import React, {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import jsPDF from "jspdf"; // Importing jsPDF

function TabContent({activeTab}) {
  const navigate = useNavigate();

  // const apiurl = "http://localhost:5001/"
  const apiurl = "https://market.lawblocks.io:5001/";
  const [signedPdfs, setSignedPdfs] = useState([]);
  const [sharedPdf, setsharedPdf] = useState([]);
  const [completed, setcompleted] = useState([]);
  const [loader, setloader] = useState(true);

  useEffect(() => {
    fetchSignedPdfs();
    getSharedpdf();
  }, []);

  const fetchSignedPdfs = async () => {
    try {
      const response = await axios.get(apiurl + "api/signed-pdfs");
      setSignedPdfs(response.data.data);
      setloader(false);
      var arr = [];
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        if (element.status == 1) {
          arr.push(element);
        }
      }
      setcompleted(arr);
    } catch (error) {
      console.error("Error fetching signed PDFs:", error);
    }
  };

  const getSharedpdf = async () => {
    try {
      const response = await axios.get(apiurl + "api/getsharedPdf");

      setsharedPdf(response.data.data);
      setloader(false);
    } catch (error) {
      console.error("Error fetching signed PDFs:", error);
    }
  };

  const downloadPdf = (value) => {
    console.log(value, "=-=-=-value");
    const pdf = new jsPDF("p", "pt", "a4");

    // Calculate the width and height of the image based on the PDF size
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (imgWidth * 841) / 595;
    // Add the image to the PDF
    pdf.addImage(value.file, "PNG", 0, 0, imgWidth, imgHeight);

    // Download the PDF
    pdf.save(`sign_${value.fileName}`);

    // const cleanedBase64String = value.file.replace(/^data:application\/pdf;base64,/, '');

    // const byteCharacters = atob(cleanedBase64String);

    // // Convert the binary string to an array of bytes
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }

    // // Convert the array of bytes to a Blob
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], { type: 'application/pdf' });

    // // Create a download link and trigger the download
    // const link = document.createElement('a');
    // link.href = window.URL.createObjectURL(blob);
    // link.download = value.fileName; // Set the file name for the download
    // link.click();
  };

  const viewAction = (value) => {
    navigate(`/digital_sign?${value}`);
  };
  const renderTable = () => {
    switch (activeTab) {
      // case "Inbox":
      //   return (
      //     <>
      //       <h5 class="modal-title mb-6 margin_botton_20">Inbox</h5>
      //       <table className="responsive-table">
      //         <thead>
      //           <tr>
      //             <th>Name</th>
      //             <th>Status</th>
      //             <th>Last Change</th>
      //             <th>Action</th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           <tr>
      //             <td>Albert Flores</td>
      //             <td>
      //               <span className="status-icon">✖</span> Need to sign
      //             </td>
      //             <td>
      //               <div>08/08/24</div>
      //               <div className="subtext">12:11:36 am</div>
      //             </td>
      //             <td>
      //               <button className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
      //                 Sign
      //               </button>
      //             </td>
      //           </tr>
      //         </tbody>
      //       </table>
      //     </>
      //   );

      case "Sent":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Sent</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Signer</th>
                  <th>File name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              {signedPdfs.length > 0 ? (
                signedPdfs.map((data, i) => (
                  <tbody key={i}>
                    <tr>
                      <td className="d-flex">
                        {" "}
                        <p style={{color: "#FE2712"}}>*</p>
                        {data.signer.split("@")[0]}
                      </td>
                      <td>{data.fileName}</td>
                      <td>
                        {data.status == 0 ? (
                          <>
                            <span className="status-icon">✖</span> Need to sign
                          </>
                        ) : (
                          <>Signed</>
                        )}
                      </td>
                      <td>
                        <div>{moment(data.date).format("lll")}</div>
                      </td>
                      <td className="gap_cls">
                        <button
                          onClick={() => viewAction(data._id)}
                          className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          onClick={() => downloadPdf(data)}
                          className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        >
                          <i class="fa fa-download"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </>
        );
      case "Completed":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Completed</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Signer</th>
                  <th>File name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              {completed.length > 0 ? (
                completed.map((data, i) => (
                  <tbody>
                    <tr key={i}>
                      <td>{data?.signer?.split("@")[0]}</td>
                      <td>{data.fileName}</td>
                      <td>
                        {data.status === 0 ? (
                          <>
                            <span className="status-icon">✖</span> Need to sign
                          </>
                        ) : (
                          <>Signed</>
                        )}
                      </td>
                      <td>
                        <div>{moment(data.date).format("lll")}</div>
                      </td>
                      <td className="gap_cls">
                        {data.status === 0 ? (
                          <button
                            onClick={() => viewAction(data._id)}
                            className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          >
                            Sign
                          </button>
                        ) : (
                          <>
                            <button
                              onClick={() => viewAction(data._id)}
                              className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                            >
                              <i class="fa fa-eye"></i>
                            </button>
                            <button
                              onClick={() => downloadPdf(data)}
                              className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                            >
                              <i class="fa fa-download"></i>
                            </button>
                            {/* <button onClick={() => viewAction(data._id)} className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                            <i class="fa fa-upload" ></i>
                          </button> */}
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="4"
                    style={{textAlign: "center", padding: "20px"}}
                  >
                    No data found
                  </td>
                </tr>
              )}
            </table>
          </>
        );
      case "ActionRequired":
        return (
          <>
            <h5 class="modal-title mb-6 margin_botton_20">Action Required</h5>
            <table className="responsive-table">
              <thead>
                <tr>
                  <th>Signer</th>
                  <th>File name</th>
                  <th>Status</th>
                  <th>Last Change</th>
                  <th>Action</th>
                </tr>
              </thead>
              {sharedPdf.length > 0 ? (
                sharedPdf.map((data, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td>{data?.DocOwn?.split("@")[0]}</td>
                        <td>{data.fileName}</td>
                        <td>
                          {data.status === 0 ? (
                            <>
                              <span className="status-icon">✖</span> Need to
                              sign
                            </>
                          ) : (
                            <>Signed</>
                          )}
                        </td>
                        <td>
                          <div>{moment(data.date).format("lll")}</div>
                        </td>
                        <td className="gap_cls">
                          {data.status === 0 ? (
                            <button
                              onClick={() => viewAction(data._id)}
                              className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                            >
                              Sign
                            </button>
                          ) : (
                            <>
                              <button
                                onClick={() => viewAction(data._id)}
                                className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                              >
                                <i class="fa fa-eye"></i>
                              </button>
                              <button
                                onClick={() => downloadPdf(data)}
                                className="rounded bg-accent py-2 px-4 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                              >
                                <i class="fa fa-download"></i>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No data found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </>
        );
      default:
        return null;
    }
  };

  return <div className="tab-content">{renderTable()}</div>;
}

export default TabContent;
