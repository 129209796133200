import React, { useEffect, useState } from "react";
import Header from './adminHeader'
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import moment from 'moment'
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function Bloglist() {
  const navigate = useNavigate()
    useEffect(() => {
        getUserDetails()
    }, [0])

    const [users, setusers] = useState([])
    const getUserDetails = async () => {
        var data = {
            apiUrl: apiService.getblogdetails,
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            setusers(resp.data)
        }
    }

    const changeStatus =  async (id ,key) => {
        if (key == "active"||key == "inactive") {
            var data = {
                apiUrl: apiService.changeStatus,
                payload : { _id : id, status : key == 'active' ? 1 : 0}
            };
            var resp = await postMethod(data)
            if(resp.status == true){
               toast.success(resp.Message)
               getUserDetails()
            }else{
                toast.error(resp.Message)
            }
        }
        if (key == "edit") {
            navigate("/admin/blogupload?"+id)
        }
    }
    return (
        <>
            <Header />
            <div className="cantainar container2 ">
                <div class="col-md-12">
                    <div class="card mb-5 pt-4 mb-xl-10">
                        <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg ">
                            <div className="uploadtbl">
                                <table class="bordered hover responsive table">
                                    <thead class="border-gray-200 fs-5 fw-bold bg-lighten">
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-6 fw-bold text-gray-600 tr_height">
                                        {users.length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">No data found</td>
                                            </tr>
                                        ) : (
                                            users.map((user, index) => (
                                                <tr key={user.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{user.metaTitle}</td>
                                                <td>{user.metaDescription}</td>
                                                <td>{user.category}</td>
                                                <td>{user.status == 1 ? "Active" : "Inactive"}</td>
                                                <td className="">
                                                    <button className='btn__Cls' onClick={() => changeStatus(user._id, "edit")}>Edit</button>{" "}
                                                    <button className='btn__Cls' onClick={() => changeStatus(user._id, "active")} >Active</button>{" "}
                                                    <button className='btn__Cls' onClick={() => changeStatus(user._id, "inactive")}>InActive</button></td>
                                                <td>{moment(user.created_at).format('lll')}</td>
                                            </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Bloglist;