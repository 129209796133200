import React, {useState, useEffect} from "react";

// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {toast} from "react-toastify";
import useStateref from "react-usestateref";
// import csc from 'country-state-city';

import {Form, Button} from "react-bootstrap";
import {Country, State, City} from "country-state-city";
import {postMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import {useNavigate, Link} from "react-router-dom";
import {Buffer} from "buffer";
import Select from "react-select";
function Home() {
  if (!("process" in window)) {
    // @ts-ignore
    window.process = {};
  }

  window.Buffer = Buffer;
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };
  var mammoth = require("mammoth");

  const [loader, setLoader] = useState(false);
  const [document, setDocument] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [arrayBuffer, setarrayBuffer, arrayBufferref] = useStateref("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [seleactedCurr, setseleactedCurr] = useState("LBT");

  const [VisbilityVal, setVisbilityVal] = useState("Select Visbility Type");
  const [DocumentType, setDocumentType] = useState([]);
  const [getDocumentType, setgetDocumentType] = useState();
  const [currency, setCurrency] = useState([]);
  const [paymentChoices, setPaymentChoices] = useState("LBT");
  const [amount, setAmount] = useState(0.000001);
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [docError, serDocError, docErrorref] = useStateref(false);
  const [nameError, serNameError, nameErrorref] = useStateref(false);
  const [descError, setdescError, descErrorref] = useStateref(false);
  const [countryError, setcountryError, countryErrorref] = useStateref(false);
  const [paymentError, setpaymentError] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [commErr, setcommErr, commErrref] = useStateref(true);
  const [currError, setcurrError] = useState(true);
  const [cateError, setcateError, cateErrorref] = useStateref(false);
  const [docCategory, setdocCategory] = useState("");
  const [categoryDetails, setCategory] = useState([{
    value: 1,
    label: "Marriage Certificate",
  },
  {
    value: 2,
    label: "Power of Attorney",
  },
  {
    value: 3,
    label: "Residential Rent Agreement",
  },
  {
    value: 4,
    label: "Non disclosure aggreement",
  },
  {
    value: 5,
    label: "Others",
  }]);
  const [selectedOption, setSelectedOption] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // setCurrency(state);
    const getCountries = async () => {
      try {
        setIsLoading(true);
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{name: firstCountry} = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
        setIsLoading(false);
      } catch (error) {
        setCountries([]);
        setIsLoading(false);
      }
    };

    getCountries();
    getCurrency();
    getcountryDocuments();
  }, []);

  const getcountryDocuments = async (event) => {
    try {
      var obj = {
        country: selectedCountry,
      };
      var data = {
        apiUrl: apiService.getcountryDocuments,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp) {
        setDocumentType(resp.Message.getcountryDocuments.documents);

        var carArray = [];
        for (
          var i = 0;
          i < resp.Message.getcountryDocuments.documents.length;
          i++
        ) {
          var obj = {
            value: i + 1,
            label: resp.Message.getcountryDocuments.documents[i],
          };
        
          carArray.push(obj);
        }
        setCategory(carArray);
      } else {
        setDocumentType([]);
      }
    } catch {}
  };
  const getCurrency = async () => {
    var options = [
      // {
      //   value: 'Indian Rupee',
      //   name: 'INR',
      // },
      // {
      //   value: 'United States Dollar',
      //   name: 'USD',
      // },
      {
        value: "Law Blocks",
        name: "LBT",
      },
    ];

    setCurrency(options);
  };

  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({isoCode, name}) => ({
          isoCode,
          name,
        }));
        const [{isoCode: firstState = ""} = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState
        );
        let allCities = [];
        allCities = result?.map(({name}) => ({
          name,
        }));
        const [{name: firstCity = ""} = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [selectedState]);

  const imageUpload = (type, val) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        var result = mammoth.convertToHtml({arrayBuffer: e.target.result});
        setarrayBuffer((await result).value);
      };
      reader.readAsArrayBuffer(val);

      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      if (
        fileExtension != "doc" &&
        fileExtension != "docx" &&
        fileExtension != "png"
      ) {
        toast.error("File does not support. only upload .docx or .doc");
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 100 MB");
      } else {
        setLoader(true);
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", "yckbfakp");
        data.append("cloud_name", "dapljmrfe");

        fetch("https://api.cloudinary.com/v1_1/dapljmrfe/auto/upload", {
          method: "post",
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            setLoader(false);
            setDocument(data.secure_url);
            // imageView()
          })
          .catch((err) => {});
      }
    } catch (error) {
      setLoader(false);
      toast("Please try again later");
    }
  };

  const chackVisibility = async (type) => {
    try {
      setVisbilityVal(type);
    } catch (error) {}
  };

  const setseleactedCurre = async (type) => {
    try {
      setseleactedCurr(type);
    } catch (error) {}
  };

  const paymentType = async (type) => {
    try {
      setPaymentChoices(type);
    } catch (error) {}
  };

  const submitTemplate = async () => {
    try {
      validate();
      if (
        commErrref.current == false &&
        docErrorref.current == false &&
        nameErrorref.current == false &&
        descErrorref.current == false &&
        countryErrorref.current == false &&
        cateErrorref.current == false
      ) {
        var obj = {
          document: document,
          selectedCountry: selectedCountry,
          selectedState: selectedState,
          fileName: arrayBufferref.current.toString(),
          selectedCity: selectedCity,
          seleactedCurr: seleactedCurr,
          VisbilityVal: "public",
          paymentChoices: paymentChoices,
          amount: amount,
          name: name,
          description: description,
          category: getDocumentType,
        };
        var data = {
          apiUrl: apiService.createTemplateAdv,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast(resp.Message);
          // navigate("/profile");
        } else {
          toast(resp.Message);
        }
      } else {
        return false;
      }
    } catch (error) {}
  };

  const validate = async (type) => {
    try {
      if (document == "") {
        serDocError(true);
        setcommErr(true);
      } else {
        serDocError(false);
        setcommErr(false);
      }

      if (name == "") {
        serNameError(true);
        setcommErr(true);
      } else {
        serNameError(false);
        setcommErr(false);
      }

      if (description == "") {
        setdescError(true);
        setcommErr(true);
      } else {
        setdescError(false);
        setcommErr(false);
      }

      if (selectedCountry == "") {
        setcountryError(true);
        setcommErr(true);
      } else {
        setcountryError(false);
        setcommErr(false);
      }

      if (paymentChoices == "") {
        setpaymentError(true);
        setcommErr(true);
      } else {
        setpaymentError(false);
        setcommErr(false);
      }

      if (docCategory == "") {
        setcateError(true);
        setcommErr(true);
      } else {
        setcateError(false);
        setcommErr(false);
      }
    } catch (error) {}
  };

  const handlechane = (e) => {
    setgetDocumentType(e.label);
    setdocCategory(e.label);
    setSelectedOption(e);
  };
  return (
    <>
      <Header />
      <section class="relative py-24">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="img/gradient_light.jpg"
            alt="gradient"
            class="h-full w-full"
          />
        </picture>
        <div class="container">
          <h1 class="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white">
            Create Template
          </h1>

          <div class="mx-auto max-w-[48.125rem]">
            <div class="mb-6">
              <label class="mb-2 block font-display text-jacarta-700 dark:text-white">
                Upload Your Template<span class="text-red">*</span>
              </label>
              <p class="mb-3 text-2xs dark:text-jacarta-300">
                Drag or choose your file to upload
              </p>

              <div class="group relative flex max-w-md flex-col items-center justify-center rounded-lg border-2 border-dashed border-jacarta-100 bg-white py-20 px-5 text-center dark:border-jacarta-600 dark:bg-jacarta-700">
                <div class="relative z-10 cursor-pointer">
                  {loader == false ? (
                    <>
                      {!document ? (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M16 13l6.964 4.062-2.973.85 2.125 3.681-1.732 1-2.125-3.68-2.223 2.15L16 13zm-2-7h2v2h5a1 1 0 0 1 1 1v4h-2v-3H10v10h4v2H9a1 1 0 0 1-1-1v-5H6v-2h2V9a1 1 0 0 1 1-1h5V6zM4 14v2H2v-2h2zm0-4v2H2v-2h2zm0-4v2H2V6h2zm0-4v2H2V2h2zm4 0v2H6V2h2zm4 0v2h-2V2h2zm4 0v2h-2V2h2z" />
                          </svg>
                          <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                            Supported File types are DOC, DOCX size: 100 MB
                          </p>
                        </>
                      ) : (
                        <img
                          width="100px"
                          height="100px"
                          class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                          src={require("../img/docx.png")}
                        ></img>
                      )}
                    </>
                  ) : (
                    <>
                      <img
                        width="60"
                        height="60"
                        class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                        src={require("../img/loadinggif.gif")}
                      ></img>
                      <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                        Please wait uploading...
                      </p>
                    </>
                  )}
                </div>
                <div class="absolute inset-4 cursor-pointer rounded bg-jacarta-50 opacity-0 group-hover:opacity-100 dark:bg-jacarta-600"></div>
                <input
                  type="file"
                  id="file-upload"
                  class="absolute inset-0 z-20 cursor-pointer opacity-0"
                  name="image"
                  onChange={(e) =>
                    imageUpload("templateDOCX", e.target.files[0])
                  }
                />
              </div>
              {docError == true ? (
                <p class="text-red">Template is required!</p>
              ) : (
                ""
              )}
            </div>

            <div class="mb-6">
              <label
                for="item-name"
                class="mb-2 block font-display text-jacarta-700 dark:text-white"
              >
                Template Name<span class="text-red"> </span>
              </label>
              <input
                type="text"
                id="item-name"
                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                placeholder="Template Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
              {nameError == true ? (
                <p class="text-red">Template name is required!</p>
              ) : (
                ""
              )}
            </div>

            <div class="mb-6">
              <label
                for="item-description"
                class="mb-2 block font-display text-jacarta-700 dark:text-white"
              >
                Description
              </label>
              <p class="mb-3 text-2xs dark:text-jacarta-300">
                Note : Only allowed 300 characters.
              </p>
              <textarea
                id="item-description"
                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                rows="4"
                required
                placeholder="Template description"
                minLength={4}
                maxLength={300}
                value={description}
                onChange={(event) => setdescription(event.target.value)}
              ></textarea>
              {descError == true ? (
                <p class="text-red">Template Description is required!</p>
              ) : (
                ""
              )}
            </div>

            <div className="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
              <div class="relative mb-6">
                <div>
                  <label class="mb-2 block font-display text-jacarta-700 dark:text-white">
                    Country
                  </label>
                </div>
                <div class="dropdown my-1 cursor-pointer">
                  <select
                    class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                    id="cars"
                    name="country"
                    value={selectedCountry}
                    onChange={(event) => setSelectedCountry(event.target.value)}
                    // onClick={(event) => getcountryDocuments(event.target.value)}
                    onBlur={(event) => getcountryDocuments(event.target.value)}
                  >
                    {countries.length > 0 ? (
                      countries.map(({isoCode, name}) => (
                        <option
                          class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                          value={isoCode}
                          key={isoCode}
                        >
                          {name}
                        </option>
                      ))
                    ) : (
                      <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                        No Countries Found!
                      </option>
                    )}
                  </select>
                  {countryError == true ? (
                    <p class="text-red">Country is required!</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div class="relative mb-6">
                <div>
                  <label class="mb-2 block font-display text-jacarta-700 dark:text-white">
                    State
                  </label>
                </div>
                <div class="dropdown my-1 cursor-pointer">
                  <select
                    class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                    name="state"
                    value={selectedState}
                    onChange={(event) => setSelectedState(event.target.value)}
                  >
                    {states.length > 0 ? (
                      states.map(({isoCode, name}) => (
                        <option
                          class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                          value={isoCode}
                          key={isoCode}
                        >
                          {name}
                        </option>
                      ))
                    ) : (
                      <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                        No States Found!
                      </option>
                    )}
                  </select>
                </div>
              </div>

              <div class="relative mb-6">
                <div>
                  <label class="mb-2 block font-display text-jacarta-700 dark:text-white">
                    City
                  </label>
                </div>
                <div class="dropdown my-1 cursor-pointer">
                  <select
                    class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                    id="cars"
                    name="city"
                    value={selectedCity}
                    onChange={(event) => setSelectedCity(event.target.value)}
                  >
                    {cities.length > 0 ? (
                      cities.map(({isoCode, name}) => (
                        <option
                          class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                          value={isoCode}
                          key={isoCode}
                        >
                          {name}
                        </option>
                      ))
                    ) : (
                      <option class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                        No Cities Found!
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div class="relative mb-6">
              <div class="dropdown my-1 cursor-pointer"></div>
            </div>
            <div class="relative mb-6">
              <div>
                <label class="mb-2 block font-display text-jacarta-700 dark:text-white">
                  Documents Categories
                </label>
              </div>

              <div class="dropdown my-1 cursor-pointer">
                {/* <div
                  class="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300"
                  role="button"
                  id="item-collection"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class=""> {getDocumentType} </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="h-4 w-4 fill-jacarta-500 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div> */}

                <Select
                  placeholder="Select Category"
                  value={selectedOption} // set selected value
                  options={categoryDetails} // set list of the data
                  onChange={handlechane} // assign onChange function
                />

                {/* <div
                  class="dropdown-menu z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl dark:bg-jacarta-800"
                  aria-labelledby="item-collection"
                >
                  {DocumentType.length > 0
                    ? DocumentType.map((item, i) => {
                        return (
                          <option
                            class="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                            value={item == "" ? "0" : item}
                            key={item}
                            onClick={(event) => handlechane(event.target.value)}
                          >
                            {item}
                          </option>
                        );
                      })
                    : "No datas found"}
                </div> */}
                {cateError == true ? (
                  <p class="text-red">Document is required!</p>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div class="mb-6">
              <label
                for="item-supply"
                class="mb-2 block font-display text-jacarta-700 dark:text-white"
              >
                Enter Amount
              </label>
              <div class="mb-3 flex items-center space-x-2">
                {/* <p class="text-2xs dark:text-jacarta-300">
                      Clients can't able to download the templates in free
                      version.
                      <span
                        class="inline-block"
                        data-tippy-content="Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing Xhibiter."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="ml-1 -mb-[3px] h-4 w-4 fill-jacarta-500 dark:fill-jacarta-300"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
                        </svg>
                      </span>
                    </p> */}
              </div>
              <input
                type="text"
                id="item-supply"
                class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                placeholder="0.000001"
                disabled
              />
              {/* 
              {paymentChoices == "paid" ? (
                <>
                  <div class="mb-3 flex items-center space-x-2">
                    <p class="text-2xs dark:text-jacarta-300">
                      Clients can able to download the templates in free
                      version.
                      <span
                        class="inline-block"
                        data-tippy-content="Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing Xhibiter."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="ml-1 -mb-[3px] h-4 w-4 fill-jacarta-500 dark:fill-jacarta-300"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
                        </svg>
                      </span>
                    </p>
                  </div>
                  <input
                    type="number"
                    id="item-supply"
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="Enter Amount"
                    name="paymentTerms"
                    value={amount}
                    onChange={(event) => setAmount(event.target.value)}
                  />
                  {amountError == true ? (
                    <p class="text-red"> Amount is required!</p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <div class="mb-3 flex items-center space-x-2">
                    <p class="text-2xs dark:text-jacarta-300">
                      Clients can't able to download the templates in free
                      version.
                      <span
                        class="inline-block"
                        data-tippy-content="Setting your asset as explicit and sensitive content, like pornography and other not safe for work (NSFW) content, will protect users with safe search while browsing Xhibiter."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="ml-1 -mb-[3px] h-4 w-4 fill-jacarta-500 dark:fill-jacarta-300"
                        >
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v2h-2V7zm0 4h2v6h-2v-6z"></path>
                        </svg>
                      </span>
                    </p>
                  </div>
                  <input
                    type="text"
                    id="item-supply"
                    class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                    placeholder="0"
                    disabled
                  />
                </>
              )} */}
            </div>
            {buttonLoader == false ? (
              <button
                onClick={submitTemplate}
                style={{cursor: "pointer"}}
                class="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Submit
              </button>
            ) : (
              <button
                disabled
                class="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all"
              >
                Loading...
              </button>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
