import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { getAuthToken } from "../core/lib/localStorage";
import { env } from "../core/service/envconfig";
import useStateRef from "react-usestateref";
import { getWeb3, keys } from "../core/helper/getWeb3";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import ReCAPTCHA from "react-google-recaptcha";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const navigate = useNavigate();

  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };
  var web3 = getWeb3()
  const [authToken, setAuthToke] = useState("");
  var url = window.location.href.split("?//")[1];
  if (url) {
  } else {
    url = "";
  }
  const valid = url.split("//")[0];
  const _id = url.split("//")[1];

  useEffect(() => {
    let setTokend = getAuthToken();
    if (setTokend != "" && setTokend != undefined && setTokend != null) {
      navigate("/");
    }
    setAuthToke(setTokend);
    Verification();
  }, [0]);

  const Verification = async () => {
    if (valid) {
      var obj = {
        valid: valid,
        _id: _id,
      };
      var data = {
        apiUrl: apiService.emailVerify,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        toast("Yours Account is verified Login to continue");
      } else {
        toast("Something went wroung");
      }
    }
  };

  const cliendValue = {
    Email: "",
    Password: "",
  };
  const advacateValue = {
    Email: "",
    Password: "",
  };

  const [cliendinputvalue, setcliendinputvalue] = useState(cliendValue);
  const [advacateinputvalue, setadvacateinputvalue] = useState(advacateValue);
  const [cliendEmailErr, setcliendEmailErr, cliendEmailErrref] =
    useStateRef(false);
  const [cliendPasswordErr, setcliendPasswordErr, cliendPasswordErrref] =
    useStateRef(false);
  const [advacateEmailErr, setadvacateEmailErr, advacateEmailErrref] =
    useStateRef(false);
  const [advacatePasswordErr, setadvacatePasswordErr, advacatePasswordErrref] =
    useStateRef(false);
  const [advacateValidation, setadvacateValidation] = useState(false);
  const [clientvalidation, setclientvalidation] = useState(false);
  const [clientbuttonLoader, setclientbuttonLoader] = useState(false);
  const [advacatebuttonLoader, setadvacatebuttonLoader] = useState(false);

  const getCliendValue = (e) => {
    const { name, value } = e.target;
    const formData = { ...cliendinputvalue, ...{ [name]: value } };
    setcliendinputvalue(formData);
  };

  const getAdvacateValue = (e) => {
    const { name, value } = e.target;
    const formData = { ...advacateinputvalue, ...{ [name]: value } };
    setadvacateinputvalue(formData);
  };

  const cliendValidate = () => {
    const error = {};
    if (cliendinputvalue.Email == "") {
      error.Email = "Email is required";
      setcliendEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(cliendinputvalue.Email)
    ) {
      error.Email = "Invalid Email address";
      setcliendEmailErr(true);
    } else {
      setcliendEmailErr(false);
    }

   

    if (cliendinputvalue.Password === "") {
      error.Password = "Password is required";
      setcliendPasswordErr(true);
    } else {
      const password = cliendinputvalue.Password;
    
      const hasMinLength = password.length >= 8;
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
      // Check each condition and set specific error messages
      if (!hasUppercase) {
        error.Password = "Password must include at least one uppercase letter";
        setcliendPasswordErr(true);
      } else if (!hasLowercase) {
        error.Password = "Password must include at least one lowercase letter";
        setcliendPasswordErr(true);
      } else if (!hasNumber) {
        error.Password = "Password must include at least one number";
        setcliendPasswordErr(true);
      } else  if (!hasMinLength) {
        error.Password = "Password must be at least 8 characters long";
        setcliendPasswordErr(true);
      } else if (!hasSpecialChar) {
        error.Password = "Password must include at least one special character";
        setcliendPasswordErr(true);
      } else {
        setcliendPasswordErr(false); // Password is strong
      }
    }

    setclientvalidation(error);
  };
  const [Captcha, setCaptcha] = useState("");

  const advacateValidate = () => {
    const error = {};
    if (advacateinputvalue.Email == "") {
      error.Email = "Email is required";
      setadvacateEmailErr(true);
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(advacateinputvalue.Email)
    ) {
      error.Email = "Invalid Email address";
      setadvacateEmailErr(true);
    } else {
      setadvacateEmailErr(false);
    }
    if (advacateinputvalue.Password === "") {
      error.Password = "Password is required";
      setadvacatePasswordErr(true);
    } else {
      const password = advacateinputvalue.Password;
    
      const hasMinLength = password.length >= 8;
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasNumber = /[0-9]/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    
      // Check each condition and set specific error messages
       if (!hasUppercase) {
        error.Password = "Password must include at least one uppercase letter";
        setadvacatePasswordErr(true);
      } else if (!hasLowercase) {
        error.Password = "Password must include at least one lowercase letter";
        setadvacatePasswordErr(true);
      } else if (!hasNumber) {
        error.Password = "Password must include at least one number";
        setadvacatePasswordErr(true);
      } else if (!hasMinLength) {
        error.Password = "Password must be at least 8 characters long";
        setadvacatePasswordErr(true);
      } else if (!hasSpecialChar) {
        error.Password = "Password must include at least one special character";
        setadvacatePasswordErr(true);
      } else {
        setadvacatePasswordErr(false); // Password is strong
      }
    }
    setadvacateValidation(error);
  };

  const cliendForm = async (e) => {
    e.preventDefault();
    cliendValidate();
    try {
      if (
        cliendinputvalue.Email != "" &&
        cliendEmailErrref.current == false &&
        cliendPasswordErrref.current == false &&
        cliendinputvalue.Password != ""
      ) {
        if (Captcha == "") {
          toast.error("Recaptcha is required")
          return false
        }
        var data = {
          apiUrl: apiService.clientsignin,
          payload: cliendinputvalue,
        };
        setclientbuttonLoader(true);
        var resp = await postMethod(data);
        if (resp.Status == true) {
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("Authenticates", resp.privateKey)

          localStorage.setItem("type", "client");
          localStorage.setItem(
            "decodes_client",
            resp.decrypted + "*client2023"
          );
          var web3 = getWeb3()
          if (
            localStorage.getItem("user_token") != "" &&
            localStorage.getItem("user_token") != null &&
            localStorage.getItem("user_token") != undefined
          ) {
            var web3 = await getWeb3();
            var account = web3?.eth?.accounts?.privateKeyToAccount(keys());
            web3.eth.accounts.wallet.add(account);
            var address = web3.eth.accounts.wallet[0].address;
            var obj = {
              to: address
            }
            var data2 = {
              apiUrl: apiService.transfer,
              payload: obj,
            };
            var resp2 = await postMethod(data2);
            if (resp2) {
              setclientbuttonLoader(false);
              // navigate("/clientProfilers");
              window.location.href = env.baseURL + "/clientProfilers";
            }
          }

          toast(resp.Message);
        } else {
          setclientbuttonLoader(false);
          toast(resp.Message);
        }
      }
    } catch (error) {
    }
  };

  const advacateForm = async (e) => {
    e.preventDefault();
    advacateValidate();
    try {
      if (
        advacateinputvalue.Email != "" &&
        advacateEmailErrref.current == false &&
        advacatePasswordErrref.current == false &&
        advacateinputvalue.Password != ""
      ) {
        if (Captcha == "") {
          toast.error("Recaptcha is required")
          return false
        }
        var data = {
          apiUrl: apiService.advacatesignin,
          payload: advacateinputvalue,
        };
        setadvacatebuttonLoader(true);
        var resp = await postMethod(data);
        if (resp.Status == true) {
          localStorage.setItem("user_token", resp.token);
          localStorage.setItem("Authenticates", resp.privateKey)
          localStorage.setItem(
            "decodes_advocate",
            resp.decrypted + "*advocates2023"
          );
          localStorage.setItem("type", "advocate");
          if (
            localStorage.getItem("user_token") != "" &&
            localStorage.getItem("user_token") != null &&
            localStorage.getItem("user_token") != undefined
          ) {
            var web3 = await getWeb3();
            var account = web3?.eth?.accounts?.privateKeyToAccount(keys());
            web3.eth.accounts.wallet.add(account);
            var address = web3.eth.accounts.wallet[0].address;
            var obj = {
              to: address
            }
            var data2 = {
              apiUrl: apiService.transfer,
              payload: obj,
            };
            var resp2 = await postMethod(data2);
            if (resp2) {
              toast(resp.Message)
              // navigate("/profile");
              setadvacatebuttonLoader(false);
              window.location.href = env.baseURL + "/profile";
            }
          }
        } else {
          setadvacatebuttonLoader(false);
          toast.error(resp.Message);
        }
      }
    } catch (error) {
    }
  };

  const handleSuccess = async (response) => {
    const decoded = jwtDecode(response.credential);

    console.log("Login Success:", decoded.email);

    var obj = {
      email: decoded.email,
    };
    var data = {
      apiUrl: apiService.googleSign,
      payload: obj,
    };
    var resp = await postMethod(data);
    if (resp.Status == true) {
      localStorage.setItem("user_token", resp.token);
      localStorage.setItem("Authenticates", resp.privateKey)
      localStorage.setItem(
        "decodes_advocate",
        resp.decrypted + "*advocates2023"
      );
      localStorage.setItem("type", "advocate");
      if (
        localStorage.getItem("user_token") != "" &&
        localStorage.getItem("user_token") != null &&
        localStorage.getItem("user_token") != undefined
      ) {
        var web3 = await getWeb3();
        var account = web3?.eth?.accounts?.privateKeyToAccount(keys());
        web3.eth.accounts.wallet.add(account);
        var address = web3.eth.accounts.wallet[0].address;
        var obj = {
          to: address
        }
        var data2 = {
          apiUrl: apiService.transfer,
          payload: obj,
        };
        var resp2 = await postMethod(data2);
        if (resp2) {
          toast(resp.Message)
          // navigate("/profile");
          setadvacatebuttonLoader(false);
          window.location.href = env.baseURL + "/profile";
        }
      }
    } else {
      setadvacatebuttonLoader(false);
      toast.error(resp.Message);
    }
    // You can decode the JWT token to get user information
  };

  const [showAdvacatePassword, setShowAdvacatePassword] = useState(false);
  const [showCliendPassword, setShowCliendPassword] = useState(false);

  // Toggle function for advocate password visibility
  const toggleAdvacatePasswordVisibility = () => {
    setShowAdvacatePassword((prevState) => !prevState);
  };

  const toggleCliendPasswordVisibility = () => {
    setShowCliendPassword((prevState) => !prevState);
  };
  const onChange = (value) => {
    setCaptcha(value)
  }

  const handleFailure = () => {
    console.log("Login Failed");
  };

  return (
    <>
      <section class="relative h-screen">
        <div class="lg:flex lg:h-full">
          <div class="relative text-center lg:w-1/2">
            <img
              src={require("../img/pexels.jpg")}
              alt="login"
              class="absolute h-full w-full object-cover"
            />
            <a href="javascript:void(0)" class="relative inline-block py-32 login_logo">
              <img
                src={require("../img/newlog.png")}
                class="inline-block img_logo_width"
                alt="Xhibiter | NFT Marketplace"
              />
            </a>
          </div>

          <div class="relative flex items-center justify-center p-[10%] lg:w-1/2 login_for_pading">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              />
            </picture>

            <div class="w-full max-w-[25.625rem] text-center max_width_100">
              <ul
                class="nav nav-tabs scrollbar-custom mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b border-jacarta-100 pb-px dark:border-jacarta-600 md:justify-center"
                role="tablist"
              >
                <li class="nav-item d-none" role="presentation">
                  <button
                    class="nav-link  relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                    id="ethereum-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#ethereum"
                    type="button"
                    role="tab"
                    aria-controls="ethereum"
                    aria-selected="true"
                  >
                    <span class="font-display text-base font-medium">
                      Arbitrator
                    </span>
                  </button>
                </li>
                {/* <li class="nav-item " role="presentation">
                  <button
                    class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                    id="torus-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#torus"
                    type="button"
                    role="tab"
                    aria-controls="torus"
                    aria-selected="false"
                  >
                    <span class="font-display text-base font-medium">
                      Mediator
                    </span>
                  </button>
                </li> */}
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                    id="wallet-connect-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#wallet-connect"
                    type="button"
                    role="tab"
                    aria-controls="wallet-connect"
                    aria-selected="false"
                  >
                    <span class="font-display text-base font-medium">
                      Advocate
                    </span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link relative flex items-center whitespace-nowrap py-3 px-6 text-jacarta-400 hover:text-jacarta-700 dark:hover:text-white"
                    id="Claint_login-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Claint_login"
                    type="button"
                    role="tab"
                    aria-controls="Claint_login"
                    aria-selected="false"
                  >
                    <span class="font-display text-base font-medium">
                      Client
                    </span>
                  </button>
                </li>
              </ul>

              <div class="tab-content">
                <div
                  class="tab-pane fade  d-none"
                  id="ethereum"
                  role="tabpanel"
                  aria-labelledby="ethereum-tab"
                >
                  <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                    Login as Arbitrator
                  </h1>
                  <p class="mb-10 text-lg leading-normal dark:text-jacarta-300">
                    Registration of Arbitrators only by invitation. Please
                    submit your CV for an invite on
                    <a href="#" class="text-accent">
                      Info@lawblocks.com
                    </a>
                  </p>
                  <form className="with_form_section">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Email<span class="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="item-name"
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Passwod<span class="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        id="item-name"
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Password"
                        required
                      />
                      <button
                        type="button"
                        onClick={toggleAdvacatePasswordVisibility}
                        class="absolute inset-y-0 right-0 flex items-center px-3"
                      >
                        <i class={`w3-xxxlarge ${showAdvacatePassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}></i>
                      </button>
                    </div>
                    <p class="mb-10 text-lg leading-normal dark:text-jacarta-300">
                      <a href="/forgotpassword" class="text-accent">
                        Forgot Password?
                      </a>
                    </p>
                    <div className="form_betwine">
                      {" "}
                      <Link
                        class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        to="/clientregister"
                      >
                        Register Now
                      </Link>
                      <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                        <span>Login</span>
                      </button>
                    </div>
                  </form>
                </div>

                <div
                  class="tab-pane fade "
                  id="torus"
                  role="tabpanel"
                  aria-labelledby="torus-tab"
                >
                  <div>
                    <img
                      src={require("../img/pana.png")}
                      alt=""
                      class="mb-16 inline-block w-50"
                    />
                    <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white md:text-6xl">
                      Coming soon
                    </h1>
                  </div>
                  <div className="d-none">
                    <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                      Login as Mediator
                    </h1>

                    <form className="with_form_section">
                      <div class="mb-6">
                        <label
                          for="item-name"
                          class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                        >
                          Resend your Verification E-mail as Mediator
                          <span class="text-red">*</span>
                        </label>
                        <input
                          type="text"
                          id="item-name"
                          class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                          placeholder="Email"
                          required
                        />
                      </div>

                      <div className="">
                        {" "}
                        <button class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
                          <span>Send</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div
                  class="tab-pane fade show active"
                  id="wallet-connect"
                  role="tabpanel"
                  aria-labelledby="wallet-connect-tab"
                >
                  <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                    Login as Advocate
                  </h1>

                  <form className="with_form_section">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Email<span class="text-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="item-name"
                        name="Email"
                        onChange={getAdvacateValue}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Email"
                        required
                      />
                      {advacateEmailErrref.current == true ? (
                        <p style={{ color: "red" }}>{advacateValidation.Email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Password<span class="text-red">*</span>
                      </label>
                      <input
                        type={showAdvacatePassword ? "text" : "password"}
                        id="item-name"
                        name="Password"
                        onChange={getAdvacateValue}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Password"
                        required
                      />
                     <button type="button" onClick={toggleAdvacatePasswordVisibility} className="mmmml"style={{
                        transform: "translateY(-150%)",
                        
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}>
                        <i class={`w3-xxxlarge ${showAdvacatePassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}></i>
                      </button>
                      {advacatePasswordErrref.current && <p style={{ color: "red" }}>{advacateValidation.Password}</p>}

                    </div>
                    <div class="mb-6">
                      <div>
                        <ReCAPTCHA
                          sitekey="6LfeB_spAAAAAE7muhZT2QChj2jpLtoh3T5wGROh"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div class="mb-6">
                      <div>
                        <div>
                          <div>
                            <GoogleLogin
                              onSuccess={handleSuccess}
                              onError={handleFailure}
                              ux_mode="popup" // Try this for live environment issues
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="mb-10 text-lg leading-normal dark:text-jacarta-300">
                      <a href="forgotpassword" class="text-accent">
                        Forgot Password?
                      </a>
                    </p>
                    <div className="form_betwine">
                      {" "}
                      <Link
                        class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        to="/advacateclient"
                      >
                        Register Now
                      </Link>
                      {advacatebuttonLoader == false ? (
                        <button
                          class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          type="submit"
                          onClick={advacateForm}
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          type="submit"
                        >
                          Loading...
                        </button>
                      )}
                    </div>
                  </form>
                </div>
                <div
                  class="tab-pane fade"
                  id="Claint_login"
                  role="tabpanel"
                  aria-labelledby="wallet-connect-tab"
                >
                  <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white">
                    Login as Client
                  </h1>

                  <form className="with_form_section">
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Email<span class="text-red">*</span>
                      </label>
                      <input
                        type="email"
                        id="item-name"
                        name="Email"
                        onChange={getCliendValue}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Email"
                        required
                      />
                      {cliendEmailErrref.current == true ? (
                        <p style={{ color: "red" }}>{clientvalidation.Email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="mb-6">
                      <label
                        for="item-name"
                        class="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                      >
                        Password<span class="text-red">*</span>
                      </label>
                      <input
                        type={showCliendPassword ? "text" : "password"}
                        id="item-name"
                        name="Password"
                        onChange={getCliendValue}
                        class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Password"
                        required
                      />
                      <button type="button" onClick={toggleCliendPasswordVisibility} className="mmmml"style={{
                        transform: "translateY(-150%)",
                        
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}>
                        <i class={`w3-xxxlarge ${showCliendPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}`}></i>
                      </button>
                      {cliendPasswordErrref.current == true ? (
                        <p style={{ color: "red" }}>
                          {clientvalidation.Password}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="mb-6">
                      <div>
                        <ReCAPTCHA
                          sitekey="6LfeB_spAAAAAE7muhZT2QChj2jpLtoh3T5wGROh"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <div class="mb-6">
                      <div>
                        <div>
                          <div>
                            <GoogleLogin
                              onSuccess={handleSuccess}
                              onError={handleFailure}
                              ux_mode="popup" // Try this for live environment issues
                            />
                          </div>
                        </div>

                      </div>
                    </div>
                    <p class="mb-10 text-lg leading-normal dark:text-jacarta-300">
                      <a href="forgotpassword" class="text-accent">
                        Forgot Password?
                      </a>
                    </p>
                    <div className="form_betwine">
                      {" "}
                      <Link
                        class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        to="/clientregister"
                      >
                        Register Now
                      </Link>
                      {clientbuttonLoader == false ? (
                        <button
                          class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          type="submit"
                          onClick={cliendForm}
                        >
                          Login
                        </button>
                      ) : (
                        <button
                          class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                          type="submit"
                        >
                          Loading...
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
