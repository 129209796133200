import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {getMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import {postMethod} from "../core/service/common.api";
import {toast} from "react-toastify";
import {useNavigate, Link} from "react-router-dom";
import {item} from "./ItemsDetails";

function Home() {
  var settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const [templateDetails, settemplateDetails] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getLawerDetails();
    getAllTemplateDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAllAdvocates,
      };
      setlawLoader(true);
      var resp = await getMethod(data);
      setlawLoader(false);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) {
    }
  };

  const goToProfile = async (id) => {
    try {
      navigate("/profile?" + id);
    } catch (error) {
    }
  };

  const getAllTemplateDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.templateDetails,
      };
      // setlawLoader(true);
      var resp = await getMethod(data);
      // setlawLoader(false);
      if (resp.status == true) {
        settemplateDetails(resp.Message);
      } else {
        settemplateDetails([]);
      }
    } catch (error) {
    }
  };

  // getAllAdvocates

  return (
    <>
      <Header />
      <section class="relative py-16 dark:bg-jacarta-800 md:py-24">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src={require("../img/gradient_light.jpg")}
            alt="gradient"
            class="h-full w-full"
          />
        </picture>
        <div class="container">
          <div class="mx-auto max-w-lg text-center">
            <img
              src={require("../img/pana.png")}
              alt=""
              class="mb-16 inline-block w-80"
            />
            <h1 class="mb-6 font-display text-4xl text-jacarta-700 dark:text-white md:text-6xl">
              Coming soon
            </h1>
            <p class="mb-12 text-lg leading-normal dark:text-jacarta-300">
              Stay Tuned: Something Extraordinary is Just Around the Corner!
            </p>
            <a
              href="/"
              class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              Navigate Back Home
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Home;
