import React, {useState, useEffect} from "react";
// import Sliderimg from "./Sliderimg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {Link, useNavigate} from "react-router-dom";
import {getAuthToken} from "../core/lib/localStorage";

function Home() {
  const [authToken, setAuthToke] = useState("");
  const [clsname, setclsname] = useState("invisible");
  const [clsname2, setclsname2] = useState("opacity-0");

  useEffect(() => {
    let setTokend = getAuthToken();
    setAuthToke(setTokend);
  }, [0]);

  const navigate = useNavigate();

  const mobileHeaer = (value) => {
    if (value == "show") {
      setclsname("Ainvisible");
      setclsname2("Aopacity-0");
    } else {
      setclsname("invisible");
      setclsname2("opacity-0");
    }
  };
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const login = () => {
    navigate("/login");
  };

  return (
    <>
      <header class="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors">
        <div class="flex items-center px-6 py-6 xl:px-24">
          <a href="/" class="shrink-0">
            <img
              src={require("../img/newlog.png")}
              class="dark:hidden setwidth"
              alt="Xhibiter | NFT Marketplace"
            />
            <img
              src={require("../img/newlog.png")}
              class="hidden dark:block setwidth"
              alt="Xhibiter | NFT Marketplace"
            />
          </a>

          <div
            class={`js-mobile-menu ${clsname} lg:visible fixed inset-0 z-10 ml-auto items-center mar-right_im bg-white ${clsname2} dark:bg-jacarta-800 lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent`}
          >
            <nav class="navbar w-full bg_color_menu">
              <ul class="flex flex-col lg:flex-row">
                {/* {!authToken ? (
                  ""
                ) : ( */}
                <li class="group">
                  <Link
                    class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    to="/admin/dashboard"
                  >
                    Dash board
                  </Link>
                </li>
                {/* // )} */}
                {/* {!authToken ? (
                  ""
                ) : ( */}
                <li class="group">
                  <Link
                    class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    to="/admin/contactus"
                  >
                    Contact us
                  </Link>
                </li>
                {/* )} */}
                {/* {!authToken ? (
                  ""
                ) : ( */}
                {/* )} */}

                <li class="group">
                  <Link
                    class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    to="/admin/blogs"
                  >
                    Blogs
                  </Link>
                </li>

                <li class="group">
                  <Link
                    class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    to="/admin/blogupload"
                  >
                    Blog upload
                  </Link>
                </li>

                {!authToken ? (
                  <li class="js-nav-dropdown group relative">
                    <a
                      href="#"
                      class="dropdown-toggle inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark login_btn"
                      id="navDropdown-4"
                      aria-expanded="false"
                      role="button"
                      data-bs-toggle="dropdown"
                    >
                      Login As
                      <i class="lg:hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-4 w-4 dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                        </svg>
                      </i>
                    </a>
                    <ul
                      class="dropdown-menu group-hover:visible lg:invisible left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2"
                      aria-labelledby="navDropdown-4"
                    >
                      <li>
                        <Link
                          class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                          to="/login"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Advocate{" "}
                          </span>
                        </Link>
                        {/* <a
                          href="/login"
                          class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Arbitrator
                          </span>
                        </a> */}
                      </li>
                      {/* <li>
                        <a
                          href="/login"
                          class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Mediator
                          </span>
                        </a>
                      </li> */}
                      {/* <li>
                        <a
                          href="/login"
                          class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Conciliator
                          </span>
                        </a>
                      </li> */}
                      {/* <li>
                        <a
                          href="/login"
                          class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Advocate{" "}
                          </span>
                        </a>
                      </li> */}
                      <li>
                        <Link
                          class="flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                          to="/login"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Client or User
                          </span>
                        </Link>
                        {/* <a
                          href="/login"
                          class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <span class="font-display text-sm text-jacarta-700 dark:text-white">
                            Login as Client or User
                          </span>
                        </a> */}
                      </li>
                    </ul>
                  </li>
                ) : (
                  ""
                )}

                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Free Legal
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}
                {/* 
                <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-1"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Arbitrators
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                  <ul
                    class="dropdown-menu group-hover:visible lg:invisible -left-6 top-[85%] z-10 hidden grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-5 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 rightaurhr"
                    aria-labelledby="navDropdown-1"
                  >
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Arbitration in India
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Arbitration
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Arbitration Model Clause
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Lawblocks Domestic Arbitration <br />
                          Rules, 2019
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-accent">
                          Lawblocks Arbitrators
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Indian Institute of Arbitration
                        </span>
                      </a>
                    </li>

                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          E-ADR
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Filing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Payment
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Allocation
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Notices
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Booking
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Hearing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Proceedings
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          e-Certified Copy
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Cloud Servers
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Personal Dashboards
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Lawblocks - Virtual ADR Hearing
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          How to Ensure a Smooth Virtual Hearing
                        </span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Free Legal
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                  <ul
                    class="dropdown-menu group-hover:visible lg:invisible left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 rightaurhr"
                    aria-labelledby="navDropdown-4"
                  >
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Facilities
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          What We Do
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Booking
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="/commingsoon"
                        class="flex items-center rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <span class="font-display text-sm text-jacarta-700 dark:text-white">
                          Hearing Rooms
                        </span>
                      </a>
                    </li>
                  </ul>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="/commingsoon"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Demo Graphic
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}
                {/* <li class="js-nav-dropdown group relative">
                  <a
                    href="#"
                    class="dropdown-toggle flex items-center justify-between py-3.5 font-display text-base text-jacarta-700 hover:text-accent focus:text-accent dark:text-white dark:hover:text-accent dark:focus:text-accent lg:px-5"
                    id="navDropdown-4"
                    aria-expanded="false"
                    role="button"
                    data-bs-toggle="dropdown"
                  >
                    Services
                    <i class="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </a>
                </li> */}
              </ul>
            </nav>

            {!authToken ? (
              " "
            ) : (
              <div class="ml-8 hidden lg:flex xl:ml-12 mar_rights">
                {/* <Link to="/checkout">
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                      // id="profileDropdown"
                      // aria-expanded="false"
                      // data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="bi bi-cart-check-fill h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      </svg>
                    </button>
                  </div>
                </Link> */}

                {!authToken ? (
                  ""
                ) : (
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                      id="profileDropdown"
                      aria-expanded="false"
                      data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                      </svg>
                    </button>
                    <div
                      class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                      aria-labelledby="profileDropdown"
                    >
                      {localStorage.getItem("type") == "client" ? (
                        <>
                          <a
                            href="/clientProfilers"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/clientProfilers">
                              <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                                My Profile
                              </span>
                            </Link>
                          </a>

                          <a
                            href="/ClientEditProfile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                            </svg>
                            <Link to="/ClientEditProfile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                                Edit Profile
                              </span>
                            </Link>
                          </a>
                        </>
                      ) : (
                        <>
                          <a
                            href="/profile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/profile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                                My Profile
                              </span>
                            </Link>
                          </a>

                          <a
                            href="/editprofile"
                            class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                            </svg>
                            <Link to="/editprofile">
                              <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                                Edit Profile
                              </span>
                            </Link>
                          </a>
                        </>
                      )}

                      <a
                        onClick={logout}
                        class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                        </svg>
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          Sign out
                        </span>
                      </a>
                    </div>
                  </div>
                )}

                {/* <a
                  href="#"
                  class="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
                  </svg>
                </a> */}
              </div>
            )}
          </div>

          <div class="!ml-auto flex lg:hidden mr-0">
            {!authToken ? (
              ""
            ) : (
              <>
                <div class="js-nav-dropdown group-dropdown relative">
                  <button
                    class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                    id="profileDropdown"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                    aria-label="profile"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                  </button>
                  <div
                    class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    {localStorage.getItem("type") == "client" ? (
                      <>
                        <a
                          href="/clientProfilers"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/clientProfilers">
                            <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                              My Profile
                            </span>
                          </Link>
                        </a>

                        <a
                          href="/ClientEditProfile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                          </svg>
                          <Link to="/ClientEditProfile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                              Edit Profile
                            </span>
                          </Link>
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          href="/profile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/profile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                              My Profile
                            </span>
                          </Link>
                        </a>

                        <a
                          href="/editprofile"
                          class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
                          </svg>
                          <Link to="/editprofile">
                            <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                              Edit Profile
                            </span>
                          </Link>
                        </a>
                      </>
                    )}

                    <a
                      onClick={logout}
                      class="flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                      </svg>
                      <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                        Sign out
                      </span>
                    </a>
                  </div>
                </div>
                <Link to="/checkout">
                  <div class="js-nav-dropdown group-dropdown relative">
                    <button
                      class="dropdown-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
                      // id="profileDropdown"
                      // aria-expanded="false"
                      // data-bs-toggle="dropdown"
                      aria-label="profile"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="bi bi-cart-check-fill h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white color_blsce"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                      </svg>
                    </button>

                    {/* <div
                    class="dropdown-menu group-dropdown-hover:visible lg:invisible !-right-4 !top-[85%] !left-auto z-10 hidden min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full group-dropdown-hover:opacity-100 dark:bg-jacarta-800 lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl"
                    aria-labelledby="profileDropdown"
                  >
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n">
                      <div className="image">
                        <img
                          src="https://res.cloudinary.com/demo/image/upload/single_page_pdf.jpg"
                          alt="item 1"
                          class="swiper-lazy h-[430px] w-full object-cover"
                        />
                      </div>
                      <div className="contant">
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          devorce template
                        </span>
                        <span class="mt-1 font-display text-sm text-jacarta-700 dark:text-white">
                          Lawer name
                        </span>
                      </div>
                      <div className="close">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 fill-jacarta-700 transition-colors dark:fill-white"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    </div>
                    <button className="cart_menu flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors hover:bg-jacarta-50 hover:text-accent focus:text-accent dark:hover:bg-jacarta-600 w-100_n w-100_n font_size_16">
                      View All
                    </button>
                  </div> */}
                  </div>
                </Link>
              </>
            )}

            {/* <a
              href="#"
              class="js-dark-mode-trigger group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="dark-mode-light h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="dark-mode-dark hidden h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z" />
              </svg>
            </a> */}

            <button
              onClick={(e) => mobileHeaer("show")}
              class="js-mobile-toggle group ml-2 flex h-10 w-10 items-center justify-center rounded-full border border-jacarta-100 bg-white transition-colors hover:border-transparent hover:bg-accent focus:border-transparent focus:bg-accent dark:border-transparent dark:bg-white/[.15] dark:hover:bg-accent"
              aria-label="open mobile menu"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="h-4 w-4 fill-jacarta-700 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {!authToken ? (
            ""
          ) : (
            // <span class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
            //   <button onClick={logout}>logout</button>
            // </span>
            <></>
          )}

          {/* <a
            class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
          >
          </a> */}
        </div>
      </header>
    </>
  );
}

export default Home;
