import React, { useState, useEffect, useRef } from "react";
import Header from "../pages/Header";
import axios from "axios";
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { Configuration, OpenAIApi } from "openai";
import { Icons } from "react-toastify";
import Footer from "./Footer";
import useStateRef from "react-usestateref";
import { CountryDropdown } from "react-country-region-selector";

function AIchatPage() {
  const [value, setvalue, valueref] = useStateRef("");
  const [replaceReq, setreplaceReq, replaceReqref] = useStateRef("");
  const [getResponceAI, setgetResponceAI, getResponceAIref] = useStateRef("");
  const [Loader, setLoader] = useState(true);
  const [divLoader, setdivLoader] = useState(false);
  const [country, setcountry] = useState("");
  const [userInput, setuserInput, userInputref] = useStateRef("");
  const [countryerr, setcountryerr] = useState(false);
  const [totalChat, settotalChat] = useState([]);

  const [respdata, setrespdata, respdataref] = useStateRef("");

  const getInputValue = (value) => {
    setreplaceReq(value);
    setrespdata(value);
  };

  const selectCountry = (val) => {
    setcountry(val);
  };

  document.addEventListener("contextmenu", (event) => event.preventDefault());
  document.onkeydown = function (e) {
    if (replaceReqref.current != "") {
      if (e.keyCode == 13) {
        getAIresponse("");
        return false;
      }
    }
  };

  const getAIresponse = async (value) => {
    if (value == "") {
    } else {
      setreplaceReq(value);
    }

    if (replaceReqref.current != "") {
      if (country != "") {
        var inputValue = `${replaceReqref.current}, legal section in ${country}`;
        setuserInput(inputValue);
        setcountryerr(false);
        var request = {
          role: "user",
          content: userInputref.current,
        };
        totalChat.push(request);
        setvalue(replaceReqref.current);
        var obj = {
          value: userInputref.current,
        };
        var data = {
          apiUrl: apiService.getAIresponse,
          payload: obj,
        };
        setLoader(true);
        setdivLoader(true);
        setreplaceReq("");
        var resp = await postMethod(data);
        setLoader(false);
        if (resp) {
          setgetResponceAI(resp.Message[0].message.content);
          var obj = {
            role: "assist",
            content: resp.Message[0].message.content,
          };
          totalChat.push(obj);
        }
      } else {
        setcountryerr(true);
      }
    } else {
      setreplaceReq("");
    }
  };
  const chatContainerRef = useRef(null);

  // Step 2: Scroll to the bottom of the chat container whenever totalChat or Loader changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [totalChat, Loader]);
  return (
    <>
      <Header />
      <section className="section">
        <div className="container">
          <div className="chat"></div>
        </div>
      </section>
      <div className="container">
        <div className=" flex-wrap rounded-2.5xl bg-white p-10 dark:bg-jacarta-700 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16 chat_bg">
          <div className="lg:w-[100%]">
            <div>
              <h2 className="font-display text-center text-4xl font-medium text-jacarta-700 TextPrimary">
                Law Marketplace AI Chat Here
              </h2>
            </div>
            {/* <div className="AIchat">
              <div className="row aichat">
                <div className="chat_height">
                  {totalChat.map((data, i) => {
                    return (
                      <div className="col-xl-6">
                        <div
                          className={`message-container ${
                            data.role == "user" ? "left" : "right"
                          }`}
                        >
                          <h5 className="message">{data.content}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {divLoader == false ? (
                  ""
                ) : (
                  <div>
                    <div className="col-xl-6">
                      <div className="message-container right">
                        <h5 className="message">Loading...</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row aiinput">
                <div className="col-lg-12">
                  <form>
                    <div className="form-group aiinput">
                      <div className="country-cls">
                        <CountryDropdown
                          value={country}
                          onChange={(val) => selectCountry(val)}
                        />
                        {countryerr == true ? (
                          <p style={{color: "red"}}>Country is required</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div class="input-with-image">
                        <input
                          type="text"
                          placeholder="Send message"
                          onChange={(e) => getInputValue(e.target.value)}
                          className="form-control mx-auto block"
                          value={replaceReqref.current}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                        {Loader == true ? (
                          <div>
                            <button type="submit">
                              <img
                                src={require("../img/SEND_ICON.png")}
                                alt="Send Icon"
                              />
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button onClick={getAIresponse}>
                              <img
                                src={require("../img/SEND_ICON.png")}
                                alt="Send Icon"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div> */}
            <div class="AIchat dark:bg-jacarta-700">
              <div class="static_chat_content">
                <div class="height_chat">
                  {divLoader == false ? (
                    <div>
                      <div class="big_head">
                        <div class="side_cotent">
                          <ul>
                            <li>
                              <img
                                classname=""
                                width="30px"
                                src="	https://res.cloudinary.com/dapljmrfe/image/upload/v1718874088/mv2uecvpewmoivh1kscq.jpg"
                              />
                              Hello, I am an AI-powered bot that can answer your
                              legal queries related to Indian Laws and give you
                              answers relevant to your questions.
                            </li>
                            <li>
                              <img
                                classname=""
                                width="30px"
                                src="	https://res.cloudinary.com/dapljmrfe/image/upload/v1718874088/mv2uecvpewmoivh1kscq.jpg"
                              />
                              I am not a legal advisor or lawyer. Please consult
                              a lawyer with your query to find a solution for
                              your legal issues.
                            </li>
                            <li>
                              <img
                                classname=""
                                width="30px"
                                src="	https://res.cloudinary.com/dapljmrfe/image/upload/v1718874088/mv2uecvpewmoivh1kscq.jpg"
                              />
                              I can only provide a starting ground so you
                              understand your rights better and get more
                              information regarding your questions before
                              consulting a lawyer.
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="default_chat pt-12">
                        <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "Someone's pet dog bit me, what can I do?"
                                )
                              }
                              class="defaut_cat"
                            >
                              Someone's pet dog bit me, what can I do?
                            </button>
                          </div>
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "Someone is repeatedly calling me and harassing me, what can I do?"
                                )
                              }
                              class="defaut_cat"
                            >
                              Someone is repeatedly calling me and harassing me,
                              what can I do?
                            </button>
                          </div>
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "How do I file a case against a person who has not returned my money after I lent it to them?"
                                )
                              }
                              class="defaut_cat"
                            >
                              How do I file a case against a person who has not
                              returned my money after I lent it to them?
                            </button>
                          </div>
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "What are the steps I can take towards filing for bankruptcy?"
                                )
                              }
                              class="defaut_cat"
                            >
                              What are the steps I can take towards filing for
                              bankruptcy?
                            </button>
                          </div>
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "I got into a fight with someone and he hit me, what can I do?"
                                )
                              }
                              class="defaut_cat"
                            >
                              I got into a fight with someone and he hit me,
                              what can I do?
                            </button>
                          </div>
                          <div class="">
                            <button
                              onClick={() =>
                                getAIresponse(
                                  "Someone hit my car, what can I do?"
                                )
                              }
                              class="defaut_cat"
                            >
                              Someone hit my car, what can I do?
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div ref={chatContainerRef}>
                      <div>
                        {totalChat.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className={`chat-message cls_cls ${data.role === "user"
                                  ? "user-message"
                                  : "ai-message"
                                }`}
                            >
                              <div className="message-content">
                                <div
                                  className={`message-text ${data.role === "user"
                                      ? "user-text"
                                      : "ai-text"
                                    }`}
                                >
                                  <p>{data.content}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                        {/* Render "Typing..." message after the last message if Loader is true */}
                        {Loader && (
                          <div className="chat-message ai-message">
                            <div className="message-content">
                              <div className="message-text ai-text">
                                Typing...
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div class="form_chat">
                  <div class="select">
                    <CountryDropdown
                      className=""
                      value={country}
                      onChange={(val) => selectCountry(val)}
                    />
                    <div className="validation">
                      {countryerr == true ? (
                        <p style={{ color: "red" }}>Country is required</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div class="input_form">
                    <textarea type="text"
                      placeholder="Shoot your queries here.."
                      onChange={(e) => getInputValue(e.target.value)}
                      className="form-control mx-auto block"
                      value={replaceReqref.current}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp">

                    </textarea>
                    {/* <input
                      type="text"
                      placeholder="Shoot your queries here.."
                      onChange={(e) => getInputValue(e.target.value)}
                      className="form-control mx-auto block"
                      value={replaceReqref.current}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    /> */}
                    <button onClick={() => getAIresponse("")}>
                      <i class="ri-send-plane-2-fill"></i>
                    </button>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AIchatPage;
