import React, { useEffect, useState } from "react";
import Header from './adminHeader'
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";

function Contactus() {

    useEffect(() => {
        getUserDetails()
    }, [0])

   const [users, setusers] = useState([])
    const getUserDetails = async () => {
        var data = {
            apiUrl: apiService.getcontacts,
        };
        var resp = await postMethod(data)
        if (resp.status == true) {
            setusers(resp.data)
        }
    }

    return (
        <>
            <Header />

            <div className="cantainar container2 ">
                <div class="col-md-12">
                    <div class="card mb-5 pt-4 mb-xl-10">
                        <div class="rounded-2.5xl border border-jacarta-100 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg ">
                            <div className="uploadtbl">
                                <table class="table-cls table_sing_list">
                                    <thead class="">
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Message</th>
                                            <th scope="col">Country</th>
                                            <th scope="col">Role</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {users.length === 0 ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">No data found</td>
                                            </tr>
                                        ) : (
                                            users.map((user, index) => (
                                                <tr key={user.id}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{user.name}</td>
                                                    <td>{user.message}</td>
                                                    <td>{user.country}</td>
                                                    <td>{user.advocate === 'Yes' ? "Advocate" : "Client"}</td>
                                                    <td>{user.mobile}</td>
                                                    <td>{user.email}</td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default Contactus;