import React, { useEffect, useRef, useState } from "react";
import DesktopSidebar from "./componants/digisign/DesktopSidebar";
import MobileBottomNav from "./componants/digisign/MobileBottomNav";
import useWindowSize from "./componants/digisign/useWindowSize";
import Headers from "../../pages/Header";
import SignatureCanvas from 'react-signature-canvas';
import { toast } from 'react-toastify';
import axios from 'axios'
import useStateRef from "react-usestateref";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useNavigate, Link } from "react-router-dom";
import { Rnd } from 'react-rnd';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import pdfWorker from 'pdfjs-dist/build/pdf.worker.min.js';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas'
import html2pdf from 'html2pdf.js';
import { pdfjs } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
// import  fabric  from 'fabric'


import { PDFDocument } from 'pdf-lib'; 

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

function NewLayout() {
  // const apiurl = "http://localhost:5001/"
  const apiurl = "https://market.lawblocks.io:5001/"
  const { width } = useWindowSize();

  const fonts = ['Arial', 'Courier New', 'Georgia', 'Times New Roman', 'Verdana'];

  const [selectedFont, setSelectedFont] = useState(fonts[0]);
  const [currentPage, setCurrentPage] = useState(0);

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };


  const cancel = async (value) => {
    setfile(false)
    const pdfViewerElement = pdfViewerRef.current;
    const canvas = await html2canvas(pdfViewerElement);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'pt', 'a4');

    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = (imgWidth * 841) / 595;
    pdf.addImage(value.file, 'PNG', 0, 0, imgWidth, imgHeight);
    pdf.save(`sign_${value.fileName}`);
  }

  const fileInputRef = useRef(null);

  const [pdfFile, setPdfFile, pdfFileref] = useStateRef(null);
  const [signatures, setSignatures] = useState([]);
  const [signatureMode, setSignatureMode] = useState('');
  const [typedSignature, setTypedSignature] = useState('');

  // ==============================file Upload===========================/

  const navigate = useNavigate();
  const [File, setFile, Fileref] = useStateRef("");
  const [name, setname] = useState("");
  const [Email, setEmail] = useState("");
  const [checked, setchecked, checkedref] = useStateRef(false);


  const [userDetails1, setuserDetails1] = useState({})
  const [orgfile, setorgfile] = useState({})
  const [_id, set_id] = useState("")

  useEffect(() => {

    var url = window.location.href.split('?')[1]
    if (url != undefined) {

      const getpdf = async () => {
        try {
          const response = await axios.post(apiurl + 'api/getuser', { id: url });
          if (response.data.status == true) {
            let storedPdfData = response.data.data.file;

            // Remove the base64 header (data:image/png;base64,) if it exists
            const base64Header = 'data:image/png;base64,';
            if (storedPdfData.startsWith(base64Header)) {
              storedPdfData = storedPdfData.replace(base64Header, '');
            }

            // Convert base64 to Image object
            const image = new Image();
            image.src = `data:image/png;base64,${storedPdfData}`;

            // Wait for the image to load
            image.onload = function () {
              const pdf = new jsPDF();

              // Calculate the dimensions to fit the image in the PDF correctly
              const pageWidth = pdf.internal.pageSize.getWidth();
              const pageHeight = pdf.internal.pageSize.getHeight();

              // Add the image to the PDF once loaded
              pdf.addImage(
                image,       // Image object
                'PNG',       // Image format (PNG)
                0,           // X position
                0,           // Y position
                pageWidth,   // Width of the image (fit to full page width)
                pageHeight   // Height of the image (fit to full page height)
              );

              // Generate the PDF Blob
              const pdfBlob = pdf.output('blob');
              const pdfUrl = URL.createObjectURL(pdfBlob);
              window.open(pdfUrl, '_blank');

              // Set the generated PDF URL in your state
              setElements([]);   // Reset elements
              setfile(true);     // Show the file
              set_id(response.data.data._id);  // Set the file ID from response
              setPdfFile(pdfUrl);  // Set the Blob URL to view the PDF
            }

          }
        } catch (error) {
          console.log(error)
        }
      }
      getpdf()

    }

    const getuser = async () => {
      const response = await axios.get(apiurl + 'api/getuser');
      if (response.data.status == true) {
        setuserDetails1(response.data.data)
      } else {
        setuserDetails1(response.data.data)
      }
    }
    getuser()
  }, [0])

  const checkbox = (e) => {
    setchecked(e.target.checked)
  }




  const [elements, setElements] = useState([]);
  const [isSigning, setIsSigning] = useState(false);
  const [loader, setloader] = useState(false);
  const [isAddingText, setIsAddingText] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [file, setfile] = useState(false)

  const signatureRef = useRef({});
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pdfViewerRef = useRef();

  const handleFileChange = (e) => {
    // const pdfFile = 'https://ipfs.io/ipfs/QmWXTQvikSntQebqAGqpaEKe1hQbvbhDVp78k3c8dHZmzK'

    // var fileReader = new FileReader()

    // fileReader.onload = function () {
    //   var typedarray = new Uint8Array(this.result)

    //   var pdf = new PDFAnnotate('pdf-container', typedarray, {
    //     onPageUpdated(page, oldData, newData) {
    //       console.log(page, oldData, newData);
    //     },
    //     ready(canvases) {
    //       canvases?.forEach(canvas => {
    //         // Remove the default box after the canvas is ready
    //         removeDefaultBox(canvas);

    //         canvas.on("mouse:up", (e) => {
    //           console.log("mouse clicked", e);
    //         });

    //         canvas.on("mouse:dblclick", (e) => {
    //           if (e.target && e.target.type === "rect") {
    //             const text = new fabric.Text('Updated Sign', {
    //               fill: 'green',
    //               left: e.target.left,
    //               top: e.target.top,
    //               selectable: false
    //             });
    //             canvas.remove(e.target);
    //             canvas.add(text);
    //             canvas.requestRenderAll();
    //           }
    //         });
    //       });
    //     },
    //     scale: 1.5,
    //     pageImageCompression: 'MEDIUM',
    //   });
    //   console.log(pdf,"==dff")
    //   setPdfInstance(pdf)
    // }
    // fileReader.readAsArrayBuffer(pdfFile)
    // return
    const file = e.target.files[0];
    setFile(file);

    if (file && file.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1]; // Extract the Base64 part
        setorgfile(reader.result)
      };
      reader.readAsDataURL(file);
      setPdfFile(URL.createObjectURL(file));
    } else {
      alert('Please select a PDF file.');
    }
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.currentPage);
    console.log(e.currentPage)
  };

  const saveSignature = () => {
    const signatureImage = signatureRef.current.getTrimmedCanvas().toDataURL('image/png');
    setElements([...elements, {
      type: 'signature',
      src: signatureImage,
      x: 50,
      y: 50,
      width: 200,
      height: 100,
      pageIndex: currentPage // Track the current page where the signature is placed
    }]);
    setIsSigning(false);
  };

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveUploadedSignature = () => {
    setElements([...elements, { type: 'signature', src: uploadedSignature, x: 50, y: 50, width: 200, height: 100 }]);
    setUploadedSignature(null);
  };

  const addText = () => {
    setElements([...elements, {
      type: 'text',
      text: `${typedSignature}`,
      x: 50,
      y: 50,
      width: 200,
      height: 50,
      pageIndex: currentPage // Store the current page where text is placed
    }]);
    setIsAddingText(false);
  };

  const updateElementPosition = (index, pageIndex, x, y, width, height) => {
    const newElements = [...elements];
    newElements[index] = { ...newElements[index], x, y, width, height, pageIndex };
    setElements(newElements);
  };

  const handleUploadSignature = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setUploadedSignature(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload an image file.');
    }
  };



  const nextAction = async () => {
    if (checkedref.current == false && Fileref.current) {
      if (Email != "" && name != "") {
        setfile(true)
      } else {
        toast.error('All field required')
      }
    } else {
      if (Fileref.current) {
        setfile(true)
      } else {
        toast.error("Please select file");
      }
    }

  }


  const viewSignedPDF = async () => {
    try {
      const formData = new FormData();
      formData.append("id", _id); // Assuming you have an ID to fetch the signed PDF

      // Send a request to get the signed PDF
      const response = await axios.post(apiurl + "api/get-signed-pdf", formData, {
        responseType: "blob", // Get the PDF file as a blob
      });

      // Create a Blob URL to view the PDF
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab or iframe
      window.open(pdfUrl); // Opens in a new browser tab

      // Optionally, you can embed it in an iframe for in-page view
      // document.getElementById('pdfFrame').src = pdfUrl;
    } catch (error) {
      console.error("Error viewing signed PDF:", error);
    }
  };
  const renderElements = (pageIndex) => {
    return elements
      .filter((element) => element.pageIndex === pageIndex)  // Only render elements on the current page
      .map((element, index) => (
        <Rnd
          key={index}
          size={{ width: element.width, height: element.height }}
          position={{ x: element.x, y: element.y }}
          onDragStop={(e, d) =>
            updateElementPosition(index, pageIndex, d.x, d.y, element.width, element.height)
          }
          onResizeStop={(e, direction, ref, delta, position) => {
            updateElementPosition(
              index,
              pageIndex,
              position.x,
              position.y,
              parseInt(ref.style.width, 10),
              parseInt(ref.style.height, 10)
            );
          }}
          style={{ border: '3px solid #000', padding: '4px', borderRadius: '4px' }}
        >
          {element.type === 'signature' ? (
            <img
              src={element.src}
              alt={`signature-${index}`}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                fontSize: '16px',
                padding: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {element.text}
            </div>
          )}
        </Rnd>
      ));
  };



  // Function to add signatures and save the PDF
  // const saveToPDF = async () => {
  //   try {

  //       const existingPdfBytes = await fetch(pdfFileref.current).then((res) => res.arrayBuffer());

  //       // Load the PDFDocument
  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);

  //       // Embed signature for each element
  //       for (let element of elements) {
  //         if (typeof element.pageIndex !== 'number' || element.pageIndex < 0) {
  //           console.error('Invalid pageIndex for element:', element);
  //           continue; // Skip this element if the pageIndex is invalid
  //         }

  //         const signatureImageBytes = await fetch(element.src).then((res) => res.arrayBuffer());
  //         const signatureImageObject = await pdfDoc.embedPng(signatureImageBytes);

  //         // Get the page where the signature was placed
  //         const page = pdfDoc.getPage(element.pageIndex);

  //         // Get the size of the PDF page
  //         const { width: pdfPageWidth, height: pdfPageHeight } = page.getSize();

  //         // Assuming the canvas is the same size as the PDF page, adjust scaling
  //         const canvasWidth = signatureRef.current.getTrimmedCanvas().width;
  //         const canvasHeight = signatureRef.current.getTrimmedCanvas().height;

  //         // Calculate scaling factors
  //         const scaleX = pdfPageWidth / canvasWidth;
  //         const scaleY = pdfPageHeight / canvasHeight;

  //         // Adjust the position and size of the signature
  //         const signatureX = element.x * scaleX; // Scale the x position
  //         const signatureY = pdfPageHeight - (element.y * scaleY + element.height * scaleY); // Scale the y position and adjust for PDF's bottom-left origin
  //       console.log(element,'=signatureX',signatureY,'===',element.width * scaleX,'====',element.height * scaleY)
  //         // Draw the signature on the correct page
  //         page.drawImage(signatureImageObject, {
  //           x: element.x -70,
  //           y: element.y + 680,
  //           width: element.width - 125 ,  // Scale the width
  //           height: element.height - 60, // Scale the height
  //         });
  //       }

  //       // Save or download the updated PDF
  //       const pdfBytes = await pdfDoc.save();
  //       const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  //       const url = URL.createObjectURL(pdfBlob);

  //       setPdfFile(url);  // Opens in a new tab
  //     setloader(false);
  //     return
  //     // Post to your API if necessary
  //     const formData = new FormData();
  //     formData.append("pdf", pdfBlob);
  //     formData.append("id", _id);
  //     formData.append("email", Email === "" ? userDetails1.email : Email);
  //     formData.append("name", name === "" ? userDetails1.fullName : name);

  //     const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     if (response.data.status === true) {
  //       navigate('/digitalDash');
  //       toast.success(response.data.Message);
  //     } else {
  //       toast.error(response.data.Message);
  //     }

  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //   }
  // };




  // const saveToPDF = async () => {
  //   try {
  //     const file = Fileref.current; // PDF file reference
  //     const formData = new FormData();
  //     formData.append("pdf", file);
  //     formData.append("id", _id);

  //     // Set status based on _id existence
  //     formData.append("status", _id ? 1 : 0);

  //     // Set email and name, defaulting to userDetails1 values if the inputs are empty
  //     formData.append("email", Email || userDetails1.email);
  //     formData.append("name", name || userDetails1.fullName);

  //     setloader(true);

  //     // Load the PDF using pdf.js
  //     const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
  //     const pdf = await loadingTask.promise;

  //     // Loop through each page of the PDF and convert to image
  //     let imgDataList = [];
  //     for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
  //       const page = await pdf.getPage(pageNum);
  //       const viewport = page.getViewport({ scale: 1.5 }); // Adjust the scale as needed
  //       const canvas = document.createElement('canvas');
  //       const context = canvas.getContext('2d');
  //       canvas.height = viewport.height;
  //       canvas.width = viewport.width;

  //       // Render PDF page into canvas context
  //       const renderContext = {
  //         canvasContext: context,
  //         viewport: viewport,
  //       };
  //       await page.render(renderContext).promise;

  //       // Convert canvas to image data
  //       const imgData = canvas.toDataURL('image/png');
  //       imgDataList.push(imgData); // Store image data of each page
  //     }

  //     // Now send all the images (from all pages) to the server as part of the formData
  //     imgDataList.forEach((imgData, index) => {
  //       formData.append(`file_page_${index + 1}`, imgData); // Append each page image data
  //     });

  //     const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     setloader(false);

  //     // Handle server response
  //     if (response.data.status === true) {
  //       toast.success(response.data.Message);
  //       navigate('/digitalDash');
  //     } else {
  //       toast.error(response.data.Message);
  //     }
  //   } catch (error) {
  //     console.error("Error generating PDF:", error);
  //     setloader(false);
  //     toast.error("An error occurred while processing the PDF.");
  //   }
  // };



  const saveToPDF = async () => {
    try {
      const existingPdfBytes = await fetch(pdfFileref.current).then((res) => res.arrayBuffer());

      // Load the entire PDF
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Iterate over all elements (signatures or text)
      for (let element of elements) {
        if (element.type === 'signature') {
          // Fetch the signature image
          const signatureImageBytes = await fetch(element.src).then((res) => res.arrayBuffer());
          const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

          // Ensure that the correct page is selected using element.pageIndex
          const page = pdfDoc.getPage(element.pageIndex);  // Get the correct page based on the stored pageIndex

          // Get the actual PDF page size for this specific page
          const { width: pdfPageWidth, height: pdfPageHeight } = page.getSize();

          // Get the viewer's dimensions (you may need to adjust this based on how your viewer works)
          const viewerWidth = pdfViewerRef.current.clientWidth;
          const viewerHeight = pdfViewerRef.current.clientHeight;

          // Calculate scaling factors for this specific page
          const scaleX = pdfPageWidth / viewerWidth;
          const scaleY = pdfPageHeight / viewerHeight;

          // Calculate correct position for the signature on this page
          const signatureX = element.x * scaleX;
          const signatureY = pdfPageHeight - (element.y + element.height) * scaleY;

          // Scale the width and height of the signature based on the page size
          const signatureWidth = element.width * scaleX;
          const signatureHeight = element.height * scaleY;

          // Draw the signature image onto the correct page
          page.drawImage(signatureImage, {
            x: signatureX,
            y: signatureY,
            width: signatureWidth,
            height: signatureHeight,
          });
        } else if (element.type === 'text') {
          // Handle text elements on the correct page as well
          const page = pdfDoc.getPage(element.pageIndex);  // Ensure text is placed on the correct page
          const { width: pdfPageWidth, height: pdfPageHeight } = page.getSize();
          const viewerWidth = pdfViewerRef.current.clientWidth;
          const viewerHeight = pdfViewerRef.current.clientHeight;

          // Calculate scaling factors for this specific page
          const scaleX = pdfPageWidth / viewerWidth;
          const scaleY = pdfPageHeight / viewerHeight;
          const textX = element.x * scaleX;
          const textY = pdfPageHeight - (element.y + element.height) * scaleY;

          // Add text to the PDF
          page.drawText(element.text, {
            x: textX,
            y: textY,
            size: 24,  // Adjust the font size as needed
            font: await pdfDoc.embedFont(PDFDocument.PDFFonts.Helvetica),
          });
        }
      }

      // Save the updated PDF
      const pdfBytes = await pdfDoc.save();

      // Convert the updated PDF to a Blob and create a URL for it
      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Set the updated PDF URL to the state to display it
      setPdfFile(pdfUrl);
      window.open(pdfUrl, '_blank');
      return
      const formData = new FormData();
      formData.append("pdf", Fileref.current);
      formData.append("id", _id);
      // formData.append("file", imgData);
      if (_id == "") {
        formData.append("status", 0);
      } else {
        formData.append("status", 1);
      }
      formData.append("email", Email == "" ? userDetails1.email : Email);
      formData.append("name", name == "" ? userDetails1.fullName : name);
      setloader(true)
      const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setloader(false)
      if (response.data.status == true) {
        navigate('/digitalDash')
        toast.success(response.data.Message)
      } else {
        toast.error(response.data.Message)
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  //   const saveToPDF = async () => {
  //     try {
  //         const pdfViewerElement = pdfViewerRef.current;

  //         // Adjust scale for higher resolution
  //         const canvas = await html2canvas(pdfViewerElement, { scale: 2 });
  //         const imgData = canvas.toDataURL('image/png', 1.0); // Preserve the quality

  //         const formData = new FormData();
  //         formData.append("pdf", Fileref.current);
  //         formData.append("id", _id);
  //         formData.append("file", imgData);

  //         if (_id == "") {
  //             formData.append("status", 0);
  //         } else {
  //             formData.append("status", 1);
  //         }
  //         formData.append("email", Email == "" ? userDetails1.email : Email);
  //         formData.append("name", name == "" ? userDetails1.fullName : name);

  //         setloader(true);

  //         const response = await axios.post(apiurl + "api/save-signed-pdf", formData, {
  //             headers: {
  //                 "Content-Type": "multipart/form-data",
  //             },
  //         });

  //         setloader(false);
  //         if (response.data.status === true) {
  //             navigate('/digitalDash');
  //             toast.success(response.data.Message);
  //         } else {
  //             toast.error(response.data.Message);
  //         }
  //     } catch (error) {
  //         console.error("Error generating PDF:", error);
  //     }
  // };

  return (
    <>
      <Headers />

      {file == false ?
        <>
          <section className="py-10 pt-20">
            <div className="container">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2 p-4">
                  <div className="bg-[#0D0B35] min-h-screen flex items-start justify-center w-full flex-col justify-start items-start">
                    <h2 className="text-left text-white text-3xl font-semibold mb-8">
                      Add Documents
                    </h2>
                    <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                      <label
                        htmlFor="file-upload"
                        className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center upload_section_code"
                      >
                        {Fileref.current == "" ?
                          <>
                            <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                            <span className="text-white text-lg font-medium">
                              Upload Document
                            </span></>
                          : <i class="fa fa-file-pdf-o" style={{ fontSize: "200px" }} aria-hidden="true"></i>}
                        <input id="file-upload" type="file" className="hidden" onChange={handleFileChange} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 p-4">
                  <h2 className="text-left text-white text-3xl font-semibold mb-8">
                    Add Recipients
                  </h2>
                  <div className="min-h-screen flex items-center justify-center">
                    <div className="dark:bg-jacarta-700 w-full rounded-xl p-8 py-10 space-y-6">
                      <div className="flex items-center space-x-3">
                        <input
                          type="checkbox"
                          id="only-signer"
                          onChange={checkbox}
                          className="h-5 w-5 text-[#6c41d6] bg-transparent border-2 border-[#6c41d6] rounded focus:ring-0"
                        />
                        <label
                          htmlFor="only-signer"
                          className="text-white text-lg font-medium"
                        >
                          I am the only signer
                        </label>
                      </div>
                      {checkedref.current == false ?
                        <>
                          <div className="mb-6">
                            <label
                              htmlFor="item-name"
                              className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                            >
                              Name<span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              id="item-name"
                              name="name"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                              placeholder="Enter Name"
                              required
                            />
                          </div>
                          <div className="mb-6">
                            <label
                              htmlFor="item-email"
                              className="mb-2 block font-display text-jacarta-700 dark:text-white text-left"
                            >
                              Email<span className="text-red">*</span>
                            </label>
                            <input
                              type="email"
                              id="item-email"
                              name="email"
                              value={Email}
                              onChange={(e) => setEmail(e.target.value)}
                              className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                              placeholder="Enter Email"
                              required
                            />
                          </div>
                          <div className="form_betwine flex justify-end pt-2">
                            <Link
                              className="inline-block rounded bg-accent py-2 px-5 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                              onClick={nextAction}
                            >
                              <i className="ri-user-add-line text-lg"></i>
                              <span>Add recipients</span>
                            </Link>
                          </div>
                        </> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4">
            {checkedref.current == true ?
              <div className="container-lg mx-auto flex justify-end gap_cls">
                <button
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  onClick={() => nextAction("/digitalDash")}
                >
                  Back
                </button>
                <button
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  onClick={nextAction}
                >
                  Next
                </button>
              </div> : ""}
          </footer>
        </> :
        <>
          <div className="flex min-h-screen bg-[#0D0B35] header_cls">
            {width >= 768 ? <DesktopSidebar /> : <MobileBottomNav />}
            <main className="ml-64 w-full bg-[#0D0B35] p-8 side_content">
              <div className="container mx-auto h-[100vh] overflow-y-auto bg-[#10153D] rounded-lg p-6 relative" ref={pdfViewerRef}>
                <Worker workerUrl={pdfWorker}>
                  <Viewer
                    fileUrl={pdfFileref.current}
                    onPageChange={handlePageChange} // Track the current page
                    onDocumentLoad={(e) => {
                      console.log('Document loaded', e);
                    }}
                    plugins={[defaultLayoutPluginInstance]}

                  />
                  {renderElements(currentPage)}
                </Worker>
              </div>

            </main>
            <footer className="fixed bottom-0 left-0 w-full dark:bg-jacarta-700 p-4 footer_respon">
              <div className="container-lg mx-auto flex justify-end gap_cls">
                <button
                  onClick={cancel}
                  className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                >
                  Cancel
                </button>
                {loader == true ?
                  <button

                    className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Loading...
                  </button>
                  :
                  <button
                    onClick={saveToPDF}
                    className="inline-block rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Done
                  </button>
                }
              </div>
            </footer>

            {/* Upload Modal */}
            <div
              className="modal fade"
              id="upload"
              tabIndex="-1"
              aria-labelledby="walletModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="walletModalLabel">
                      Upload Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>

                  <div className="modal-body p-6 text-center">
                    <div className="flex items-center justify-center min-h-screen bg-[#0D0B35]">
                      <label
                        htmlFor="file-upload"
                        className="w-full bg-[#10153D] p-12 rounded-lg border-dashed border-2 border-gray-600 cursor-pointer flex flex-col items-center justify-center text-center module_filw"
                      >
                        <i className="ri-upload-cloud-line text-white text-4xl mb-4"></i>
                        <span className="text-white text-lg font-medium" onClick={() => fileInputRef.current.click()}>
                          Upload Signature
                        </span>
                        <input id="file-upload" onChange={handleUploadSignature} type="file" className="hidden" />
                      </label>
                    </div>
                  </div>

                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">
                      <button
                        onClick={saveUploadedSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Draw Signature Modal */}
            <div
              className="modal fade"
              id="Draw"
              tabIndex="-1"
              aria-labelledby="DrawSignLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="DrawSignLabel">
                      Draw Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>
                  <div className="modal-body p-6 text-center">
                    <SignatureCanvas
                      penColor="black"
                      ref={signatureRef}
                      canvasProps={{ width: 400, height: 200, className: 'sigCanvas' }}
                    />
                  </div>
                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">

                      <button
                        onClick={clearSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Clear
                      </button>
                      <button
                        onClick={saveSignature}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Type Signature Modal */}
            <div
              className="modal fade"
              id="TextSign"
              tabIndex="-1"
              aria-labelledby="TextSignLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered max-w-lg justify-center">
                <div className="modal-content max-w-lg width_modlew_60">
                  <div className="modal-header model_padding">
                    <h5 className="modal-title" id="TextSignLabel">
                      Type Your Sign
                    </h5>
                    <button
                      type="button"
                      className="btn-close flex_close_button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        className="h-6 w-6 fill-jacarta-700 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                      </svg>
                    </button>
                  </div>
                  <div className="modal-body p-6 text-center">
                    <div className="text-white">
                      <input
                        type="text"
                        value={typedSignature}
                        placeholder="Type your signature"
                        onChange={(e) => setTypedSignature(e.target.value)}
                        className="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      />
                    </div>
                    <div className="mt-4">
                      <select
                        onChange={handleFontChange} value={selectedFont}
                        className="dropdown-toggle flex items-center justify-between rounded-lg border border-jacarta-100 bg-white py-3 px-3 dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-jacarta-300 max_width_100"
                      >
                        {fonts.map((font, index) => (
                          <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto" key={index} value={font}>
                            {font}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="modal-footer flex items-end justify-end py-10 model_padding">
                    <div className="flex items-end justify-end space-x-4 flex_end_sner">
                      <button
                        onClick={addText}
                        className="rounded bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Sign
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>}
    </>
  );
}

export default NewLayout;
