import React, {useState, useEffect, useRef} from "react";
import {Editor} from "@tinymce/tinymce-react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import {toast} from "react-toastify";
import {postMethod, getMethod} from "../core/service/common.api";
import apiService from "../core/service/v1/index";
// import {htmlToDocx} from "html-to-docx";
// import mammoth from 'mammoth'
import useStateref from "react-usestateref";

function Home() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      (editorRef.current.getContent());
    }
  };
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [itemDetails, setItemDetails] = useState({});
  const [docxHtml, setdocxHtml, docxHtmlref] = useStateref("");
  const [downloadUrl, setdownloadUrl, downloadUrlref] = useStateref("");
  const [upLoader, setupLoader] = useState(false);
  const [loader, setloader] = useState(false);

  // const [arrayBuffer, setarrayBuffer, arrayBufferref] = useStateref("");
  const docxDownload = async () => {
  };
  useEffect(() => {
    getItem();
  }, []);

  const getItem = async () => {
    try {
      if (window.location.href.split("?")[1] != undefined) {
        var obj = {
          id: window.location.href.split("?")[1],
        };
        var data = {
          apiUrl: apiService.getTemplateIDEdit,
          payload: obj,
        };
        setloader(true);
        var resp = await postMethod(data);
        setloader(false);
        if (resp.status == true) {
          setItemDetails(resp.Message);
          setdocxHtml(resp.Message.docHtml);
          setdownloadUrl(resp.Message.document);
        } else {
        }
      } else {
      }
    } catch (error) {
      setloader(false);
    }
  };

  const uploadDoc = async () => {
    var obj = {
      data: docxHtmlref.current,
      id: window.location.href.split("?")[1],
    };
    var data = {
      apiUrl: apiService.updatedocx,
      payload: obj,
    };
    setupLoader(true);
    var resp = await postMethod(data);
    setupLoader(false);
    if (resp.Status == true) {
      getItem();
      toast(resp.Message);
    } else {
      toast(resp.Message);
    }
  };
  const changeValue = async (changes) => {
    setdocxHtml(changes);
  };

  return (
    <>
      <Header />

      <section class="relative py-24">
        <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="img/gradient_light.jpg"
            alt="gradient"
            class="h-full w-full"
          />
        </picture>

        {loader == true ? (
          <div class="loader_cwnte mb-4">
            <img
              width="60"
              height="60"
              class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
              src={require("../img/loadinggif.gif")}
            ></img>
          </div>
        ) : (
          <div class="container">
            <h1 class="py-16 text-center font-display text-4xl font-medium text-jacarta-700 dark:text-white">
              View Your Purchased Documents
            </h1>
            <div class="mx-auto max-w-[48.125rem]">
              <div class="mb-6">
                <label class="mb-2 block font-display text-jacarta-700 dark:text-white text-center">
                  Edit Your Template
                </label>
                <div class="docx_editor">
                  <Editor
                    value={docxHtml}
                    init={{
                      height: "100vh",
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen powerpaste casechange",
                        "directionality advcode visualchars mediaembed codesample pagebreak",
                        "nonbreaking tableofcontents checklist tinymcespellchecker editimage",
                        "formatpainter permanentpen linkchecker emoticons advtable export autosave",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      menubar: false,
                      content_style: "body img{ width:100%; }",
                      toolbar:
                        "undo redo | styleselect | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | table tabledelete | \
                      tableprops tablerowprops tablecellprops | \
                      tableinsertrowbefore tableinsertrowafter tabledeleterow | \
                      tableinsertcolbefore tableinsertcolafter tabledeletecol | help",
                    }}
                    onEditorChange={changeValue}
                  />
                </div>
              </div>
              <div className="buton_edit_dse">
                <a href={downloadUrlref.current}>
                  <button
                    style={{cursor: "pointer"}}
                    onClick={docxDownload}
                    // style={{cursor: "pointer"}}
                    class="cursor-default rounded-full bg-accent-lighter py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    Download
                  </button>
                </a>
                <div className="upload_btn">
                  {upLoader == true ? (
                    <button>Loading...</button>
                  ) : (
                    <button onClick={uploadDoc}>Upload</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}

export default Home;
