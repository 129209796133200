import React, { useEffect, useState } from "react";
import Header from "./Header"
import apiService from "../core/service/v1";
import { postMethod } from "../core/service/common.api";
import { useNavigate } from "react-router-dom";
function Arbitrator() {
    const navigate = useNavigate()
    useEffect(() => {
        getsharelist();
    }, [])
    const [ArbitratorList, setArbitratorList] = useState([])
    const [loader, setloader] = useState(false)
    const getsharelist = async () => {
        var data = {
            apiUrl: apiService.getadvocateList,
        };
        setloader(true)
        var resp = await postMethod(data)
        setloader(false)
        if (resp.status == true) {
            var arr = []
            for (let i = 0; i < resp.Arbitrator.length; i++) {
                const element = resp.Arbitrator[i];
                console.log(element)
                if (element.country != "India"&&element.prfileIMG != "") {
                    arr.push(element)
                }
            }
            for (let i = 0; i < resp.Arbitrator.length; i++) {
                const element = resp.Arbitrator[i];
                console.log(element)
                if (element.country == "India"&&element.prfileIMG != "") {
                    arr.push(element)
                }
            }
            setArbitratorList(arr)
        }
    }

    const goToProfile = async (id) => {
        try {
            navigate("/profile?" + id);
        } catch (error) {
        }
    };
    return (
        <>
            <Header />
            <section class="relative py-24 dark:bg-jacarta-800">
                <div class="container">
                    <div class="mb-12 text-center font-display text-3xl text-jacarta-700 dark:text-white">
                        <h2 class="inline"> Arbitrators Panel </h2>
                    </div>

                    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-3">
                        {ArbitratorList.map((data, i) => {
                            return (
                                <div class="card_layer posiuion">
                                    <div className="lawer_imga">
                                        {(data && data.prfileIMG == "") ||
                                            (data && data.prfileIMG == null) ? (
                                            <img
                                                src={require("../img/advocateImage-removebg-preview.png")}
                                                width="100px"
                                                height="100px"
                                                alt="avatar 1"
                                                class="rounded-2lg"
                                                loading="lazy"
                                            />
                                        ) : (
                                            <img
                                                src={data && data.prfileIMG}
                                                width="100px"
                                                height="100px"
                                                alt="avatar 1"
                                                class="rounded-2lg"
                                                loading="lazy"
                                            />
                                        )}
                                    </div>
                                    <div class="lawer_info">
                                        <h4>{data.fullName}</h4>
                                        <p><span>Experience:</span> {data.Experience}</p>
                                        <p><span>Expertise:</span> {data.exertise}</p>
                                        <p><i class="ri-map-pin-fill"></i>{data.country}</p>
                                    </div>
                                    <div className="postion_hover">
                                        {" "}
                                        <a onClick={() => goToProfile(data._id)}>View</a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {loader == true ?
                        <div class="flex_loading">
                            <img
                                width="60"
                                height="60"
                                class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                                src={require("../img/loadinggif.gif")}
                            ></img>
                        </div> :
                        ArbitratorList.length > 0 ? "" :
                            <div class="mt-10 text-center"><a
                                class="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">No data found</a></div>}
                </div>
            </section>
        </>
    );
}
export default Arbitrator;