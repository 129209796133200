import React, { useState, useEffect } from "react";
// import Sliderimg from "./Sliderimg";
import Footer from "./Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "./Header";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/v1/index";
import { postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-easy-crop";
import useStateRef from "react-usestateref";
function Home({
  image = false,
  style = {
    width: 120,
    height: 120,
    margin: 0,
    borderRadius: "50%",
    border: "1px solid #ddd",
  },
  label = false,
  urlUpload = "/users/set-avatar",
}) {
  var settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const styles = {
    backgroundImage:
      "url(https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/2764-fe0f.png)",
  };

  const [allAdvocate, setallAdvocate] = useState([]);
  const [lawLoader, setlawLoader] = useState(false);
  const [username, setusername] = useState("");
  const [bio, setBio] = useState("");
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [advateDet, setadvateDet] = useState({});
  const [prfileIMG, setPrfileIMG] = useState("");
  const [coverIMG, setCoverIMG] = useState("");
  const [zoom, setZoom] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loadeProfile, setLoaderProfile] = useState(false);

  const [usernameErr, setusernameErr] = useState(false);
  const [bioError, setbioError] = useState(false);
  const [twitterError, settwitterError] = useState(false);
  const [instagramError, setinstagramError] = useState(false);
  const [facebookError, settfbError] = useState(false);
  const [commErr, setcommErr] = useState(true);
  const [uploadingType, setUploadingType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentFile, setcurrentFile, currentFileref] = useStateRef("");
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getLawerDetails();
  }, [0]);

  const getLawerDetails = async (e) => {
    try {
      var data = {
        apiUrl: apiService.getAdvProfile,
      };
      // setlawLoader(true);
      var resp = await getMethod(data);
      // setlawLoader(false);
      setadvateDet(resp.Message);
      setusername(resp.Message.fullName);
      setBio(resp.Message.bio);
      setEmail(resp.Message.email);
      setTwitter(resp.Message.twitter);
      setInstagram(resp.Message.instagram);
      setFacebook(resp.Message.facebook);
      setPrfileIMG(resp.Message.prfileIMG);
      setCoverIMG(resp.Message.coverIMG);
      if (resp.status == true) {
        setallAdvocate(resp.Message);
      } else {
        setallAdvocate([]);
      }
    } catch (error) { }
  };
  const croppedImageUpload = async () => {
    setSelectedImage(null);
    const fileExtension = currentFileref.current.name.split(".").at(-1);
    const fileSize = currentFileref.current.size;
    if (
      fileExtension != "jpeg" &&
      fileExtension != "png" &&
      fileExtension != "jpg"
    ) {
      toast.error("File does not support. only upload .jpeg or .png or jpg");
    } else if (fileSize > 10000000) {
      toast.error("Please upload a file smaller than 100 MB");
    } else {
      if (uploadingType == "coverPhoto") {
        setLoader(true);
      } else if (uploadingType == "profilePhoto") {
        setLoaderProfile(true);
      }
      const data = new FormData();
      data.append("file", currentFileref.current);
      data.append("upload_preset", "yckbfakp");
      data.append("cloud_name", "dapljmrfe");
      fetch("https://api.cloudinary.com/v1_1/dapljmrfe/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          setLoader(false);
          setLoaderProfile(false);
          if (uploadingType == "coverPhoto") {
            setCoverIMG(data.secure_url);
          }
          if (uploadingType == "profilePhoto") {
            setPrfileIMG(data.secure_url);
            console.log(data.secure_url, "=-=-=-data.secure_url");
          }
        })
        .catch((err) => { });
    }
  };
  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    const image = new Image();
    image.src = selectedImage;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      const croppedImageDataURL = canvas.toDataURL("image/jpeg");

      const croppedImage = new Image();
      croppedImage.src = croppedImageDataURL;
      croppedImage.onload = () => {
        // Do something with the cropped image (e.g., display it in an <img> element)
      };
      canvas.toBlob((blob) => {
        const fileName = "cropped-image.jpg"; // Specify the desired file name here
        const file = new File([blob], fileName, { type: "image/jpeg" });

        // Do something with the cropped image file (e.g., upload it, save it locally, etc.)
        if (file) {
          return setcurrentFile(file);
        }
      }, "image/jpeg");
    };
  };

  const updateProfiles = async () => {
    try {
      if (username == "") {
        setusernameErr(true);
        setcommErr(true);
      } else {
        setusernameErr(false);
        setcommErr(false);
      }
      if (bio == "") {
        setbioError(true);
        setcommErr(true);
      } else {
        setbioError(false);
        setcommErr(false);
      }
      // if (twitter == "") {
      //   settwitterError(true);
      //   setcommErr(true);
      // } else {
      //   settwitterError(false);
      //   setcommErr(false);
      // }
      // if (instagram == "") {
      //   setinstagramError(true);
      //   setcommErr(true);
      // } else {
      //   setinstagramError(false);
      //   setcommErr(false);
      // }
      // if (facebook == "") {
      //   settfbError(true);
      //   setcommErr(true);
      // } else {
      //   settfbError(false);
      //   setcommErr(false);
      // }

      if (
        commErr == false &&
        usernameErr == false &&
        bioError == false
      ) {
        var obj = {
          username: username,
          bio: bio,
          twitter: twitter,
          instagram: instagram,
          facebook: facebook,
          prfileIMG: prfileIMG,
          coverIMG: coverIMG,
        };
        var data = {
          apiUrl: apiService.updateProfile,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          getLawerDetails();
          toast(resp.Message);
          navigate("/profile");
        } else {
          toast(resp.Message);
        }
      } else {
      }
    } catch (error) { }
  };

  // getAllAdvocates

  const imageUpload = (type, val) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      if (val) {
        reader.readAsDataURL(val);
      }
      setUploadingType(type);
    } catch (error) {
      // setLoader(false);
      toast("Please try again later");
    }
  };

  return (
    <>
      <Header />
      <main className="pt-[5.5rem] lg:pt-24">
        {/* <!-- Banner --> */}

        <div class="relative cover_back">
          {coverIMG == "" || coverIMG == undefined || coverIMG == null ? (
            // <img
            //   src={require("../img/blackbanner.png")}
            //   alt="banner"
            //   class="h-[18.75rem] object-cover"
            // />
            <></>
          ) : (
            <img
              src={coverIMG}
              alt="banner"
              class="h-[18.75rem] object-cover"
            />
          )}

          {loader == true ? (
            <>
              <p class="mx-auto max-w-xs text-xs dark:text-jacarta-300">
                {/* Please wait uploading... */}
                <img
                  width="60"
                  height="60"
                  class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                  src={require("../img/profileman.png")}
                ></img>
              </p>
            </>
          ) : (
            <div class="container relative -translate-y-4 cover_section_image">
              <div class="group absolute right-0 bottom-4 flex items-center rounded-lg bg-white py-2 px-4 font-display text-sm hover:bg-accent fitcontebt inner_button">
                <input
                  type="file"
                  accept="image/*"
                  class="absolute inset-0 cursor-pointer opacity-0"
                  name="image"
                  onChange={(e) => imageUpload("coverPhoto", e.target.files[0])}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="mr-1 h-4 w-4 fill-jacarta-400 group-hover:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z"></path>
                </svg>
                <span class="mt-0.5 block group-hover:text-white">
                  Edit cover photo
                </span>
              </div>
            </div>
          )}
        </div>
        {selectedImage == null ? (
          <section class="relative py-16 dark:bg-jacarta-800 ">
            <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src={require("../img/gradient_light.jpg")}
                alt="gradient"
                class="h-full w-full"
              />
            </picture>
            <div class="container">
              <div class="mx-auto max-w-[48.125rem] md:flex">
                <div class="mb-12 md:w-1/2 md:pr-8">
                  <div class="mb-6">
                    <label
                      for="profile-username"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Username<span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="profile-username"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter username"
                      required
                      name="username"
                      value={username}
                      onChange={(event) => setusername(event.target.value)}
                    />
                    {usernameErr == true ? (
                      <p class="text-red">User name is required!</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="mb-6">
                    <label
                      for="profile-bio"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Bio<span class="text-red">*</span>
                    </label>
                    <textarea
                      id="profile-bio"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      required
                      placeholder="Tell the world your story!"
                      name="bio"
                      value={bio}
                      onChange={(event) => setBio(event.target.value)}
                    ></textarea>

                    {bioError == true ? (
                      <p class="text-red">Bio is required!</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div class="mb-6">
                    <label
                      for="profile-email"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Email address<span class="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      id="profile-email"
                      class="w-full rounded-lg border-jacarta-100 py-3 hover:ring-2 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      disabled
                    />
                  </div>
                  <div class="mb-6 input_pr">
                    <label
                      for="profile-twitter"
                      class="mb-1 block font-display text-sm text-jacarta-700 dark:text-white"
                    >
                      Links
                    </label>
                    <div class="relative">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="x"
                        class="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M3.5 0L12 10.5 20.5 0h3L15 12l8.5 12h-3L12 13.5 3.5 24h-3L9 12 0 0z" />
                      </svg>

                      <input
                        type="text"
                        id="profile-twitter"
                        class="w-full rounded-t-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Twitter"
                        value={twitter}
                        onChange={(event) => setTwitter(event.target.value)}
                      />
                      {twitterError == true ? (
                        <p class="text-red red_text_potio">
                          Twitter link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="relative">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="telegram"
                        class="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path d="M248,8C111.033,8,0,119.033,0,256s111.033,248,248,248s248-111.033,248-248S384.967,8,248,8z M363.813,162.204l-42.885,201.04 c-3.257,14.469-11.828,17.992-23.919,11.221l-66.121-48.733l-31.917,30.717c-3.529,3.53-6.472,6.472-13.162,6.472 l4.704-66.631l121.296-109.515c5.282-4.704-1.187-7.36-8.186-2.656L187.728,285.916l-61.92-19.378 c-13.453-4.2-13.557-13.453,2.844-19.929l241.898-93.327C371.042,166.193,373.716,167.81,363.813,162.204z"></path>
                      </svg>

                      <input
                        type="text"
                        id="profile-instagram"
                        class="-mt-px w-full border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="Telegram"
                        value={instagram}
                        onChange={(event) => setInstagram(event.target.value)}
                      />
                      {instagramError == true ? (
                        <p class="text-red red_text_potio">
                          Instagram link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div class="relative">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="linkedin"
                        class="pointer-events-none absolute top-1/2 left-4 h-4 w-4 -translate-y-1/2 fill-jacarta-300 dark:fill-jacarta-400"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.92 0 54.22 0 24.62 24.1 0 53.79 0c29.7 0 53.7 24.62 53.7 54.22 0 29.7-24 53.88-53.7 53.88zM447.9 448h-92.6V302.4c0-34.7-13.4-58.4-47-58.4-25.6 0-40.9 17.2-47.6 33.8-2.4 5.9-3 14.1-3 22.4V448h-92.8s1.2-239.6 0-264.1h92.8v37.4c12.3-18.9 34.3-45.9 83.5-45.9 61 0 106.9 39.8 106.9 125.4V448z"></path>
                      </svg>

                      <input
                        type="url"
                        id="profile-website"
                        class="-mt-px w-full rounded-b-lg border-jacarta-100 py-3 pl-10 hover:ring-2 hover:ring-accent/10 focus:ring-inset focus:ring-accent dark:border-jacarta-600 dark:bg-jacarta-700 dark:text-white dark:placeholder:text-jacarta-300"
                        placeholder="LinkedIn"
                        value={facebook}
                        onChange={(event) => setFacebook(event.target.value)}
                      />
                      {facebookError == true ? (
                        <p class="text-red red_text_potio">
                          IinkedIn link is required!
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <button
                    onClick={updateProfiles}
                    class="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
                  >
                    Update Profile
                  </button>
                </div>
                <div class="flex space-x-5 md:w-1/2 md:pl-8">
                  <form class="shrink-0">
                    {loadeProfile == true ? (
                      <img
                        width="60"
                        height="60"
                        class="mb-4 inline-block fill-jacarta-500 dark:fill-white"
                        src={require("../img/loadinggif.gif")}
                      ></img>
                    ) : (
                      <figure class="relative inline-block img_withas">
                        {prfileIMG == "" ||
                          prfileIMG == null ||
                          prfileIMG == undefined ? (
                          <img
                            src={require("../img/advocateImage.jpeg")}
                            alt="collection avatar"
                            class="rounded-xl border-[5px] border-white dark:border-jacarta-600"
                          />
                        ) : (
                          <img
                            src={prfileIMG}
                            alt="collection avatar"
                            class="rounded-xl border-[5px] border-white dark:border-jacarta-600"
                          />
                        )}

                        <div class="group absolute -right-3 -bottom-2 h-8 w-8 overflow-hidden rounded-full border border-jacarta-100 bg-white text-center hover:border-transparent hover:bg-accent">
                          <input
                            type="file"
                            accept="image/*"
                            class="absolute top-0 left-0 w-full cursor-pointer opacity-0"
                            name="image"
                            onChange={(e) =>
                              imageUpload("profilePhoto", e.target.files[0])
                            }
                          />
                          <div class="flex h-full items-center justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              class="h-4 w-4 fill-jacarta-400 group-hover:fill-white"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 21z" />
                            </svg>
                          </div>
                        </div>
                      </figure>
                    )}
                  </form>
                  <div class="mt-4">
                    <span class="mb-3 block font-display text-sm text-jacarta-700 dark:text-white">
                      Profile Image
                    </span>
                    <p class="text-sm leading-normal dark:text-jacarta-300">
                      We recommend an image of at least 300x300. Gifs work too.
                      Max 5mb.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="relative py-16 dark:bg-jacarta-800 crop_imga_contain">
            <div className="container">
              <div className="reactEasyCrop_Container">
                {uploadingType == "coverPhoto" ? (
                  <div className="crop_img">
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={3}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                    />
                  </div>
                ) : (
                  <div className="crop_img">
                    <Cropper
                      image={selectedImage}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={handleCropComplete}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        )}
        {selectedImage != null ? (
          <div className="crop_imga_contain button">
            <button
              class="rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark"
              onClick={croppedImageUpload}
            >
              upload Image
            </button>
          </div>
        ) : (
          ""
        )}
        {/* <!-- end edit profile --> */}
      </main>

      <Footer />
    </>
  );
}

export default Home;
